import React, { useState } from 'react'
import { Autocomplete, MenuItem, Select, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import axios from 'axios';
import { authorize } from '../../helpers/common';
import { toast } from 'react-toastify';
interface EditOneToOneRoutineModalProps {
  IsOpen: any
  closeModal: any
  formValues: any
  setFormValues: any
  errors: any
  setErrors: any
  shifts: any
  setShifts: any
  forDate: any
  editChange: any
  selectedTab: any
}

type RecordStringFields = 'startTime' | 'endTime';

const EditOneToOneRoutineModal: React.FC<EditOneToOneRoutineModalProps> = ({ IsOpen, closeModal, formValues, setFormValues, errors, setErrors, shifts, setShifts, forDate, editChange, selectedTab }) => {
    
    const { organization } = useSelector((state: any) => state.Login)
    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const { staffDropdown } = useSelector((state: any) => state.Staff);
    const handleRecordChange = (e: any, recordIndex: any) => {
      const { value } = e.target;
      setShifts((prevShifts: any) => {
        const updatedShifts = [...prevShifts];
        updatedShifts[0].shift[0].record[recordIndex].staffId = value;
        return updatedShifts;
      });
    };
    
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        recordIndex: number,
        field: RecordStringFields
    ) => {
        const value = e.target.value;
        const updatedShifts = [...shifts];
        updatedShifts[0].shift[0].record[recordIndex][field] = value;
        setShifts(updatedShifts);
    };
    const addRecord = () => {
        setShifts((prevShifts: any) => {
            const newRecord = {
                distance: "",
                endTime: "",
                note: [
                    {
                        note: "",
                        time: ""
                    }
                ],
                staffId: "",
                staffName: "",
                startTime: "",
            };
            const updatedShifts = [...prevShifts];
            updatedShifts[0].shift[0].record.push(newRecord);
            return updatedShifts;
        });
    };
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);


    const confirmDeleteRecord = (index: number) => {
        setDeleteIndex(index);
        setConfirmDelete(true);
    };

    const removeRecord = async (index: any) => {

        if (deleteIndex !== null) {
            const url = `${baseURL}/oneToOne/deleteStaff?date=${forDate}&endTime=${shifts[0].shift[0].record[index].endTime}&organization=${organization}&pid=${formValues.patientId}&staffId=${shifts[0].shift[0].record[index].staffId}&startTime=${shifts[0].shift[0].record[index].startTime}`;

            await axios.delete(url)
                .then(response => {
                    setShifts((prevShifts: any) => {
                        const updatedShifts = [...prevShifts];
                        updatedShifts[0].shift[0].record.splice(deleteIndex, 1);
                        return updatedShifts;
                    });
                })
                .catch(error => {
                    console.error('There was an error deleting the record:', error);
                });
            setConfirmDelete(false);
            setDeleteIndex(null);
        }
    };
    const cancelDelete = () => {
        setConfirmDelete(false);
        setDeleteIndex(null);
    };

    
    const savePatients = async (patient: any) => {
        const { header1 } = authorize();
        try {
            let newErrors = {
                patientIncharge: !formValues.patientIncharge,
            };
            setErrors(newErrors);
            const hasErrors = Object.values(newErrors).some(error => error);
            if (hasErrors) {
                return;
            }
            let bodyData = {
                date: forDate,
                id: "",
                organization,
                pid: formValues.patientId,
                shift: shifts?.map((k: any) => ({
                    record: k.shift[0].record?.map((l: any) => ({
                        distance: "",
                        endTime: l.endTime,
                        note: [{ note: "", time: "" }],
                        staffId: l.staffId,
                        startTime: l.startTime,
                    })),
                    shiftIncharge: formValues.patientIncharge,
                    shiftName: "",
                    shiftTime: k.shift[0].shiftTime,
                })),
            };

            switch (selectedTab) {
                case "Shift-A":
                    bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-A");
                    break;
                case "Shift-B":
                    bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-B");
                    break;
                case "Shift-C":
                default:
                    bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-C");
                    break;
            }
            const response = await axios.post(`${baseURL}/oneToOne/register`, bodyData, { headers: header1 });
            if (response.data.message.code === successCode) {
                toast.success(response.data.message.description)
                editChange(patient);
                closeModal(false);
            } else {
                toast.error("Login failed: " + response.data.message.description);
            }

        } catch (error) {
            toast.error("An error occurred during register.");
        }
    }
    return (
        <div>
            <Modal isOpen={IsOpen} centered size='lg'>
                <div className="d-flex align-items-center justify-content-center vh-90">
                    <div className='row'>
                        <div className='container col-md-12'>
                            <ModalHeader toggle={() => closeModal(false)}>
                                Edit 1:1 Routine
                            </ModalHeader>
                            <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                <div className="row w-100 " style={{ marginTop: '10px' }}>
                                    <div className='col-md-12 mb-2'> Patient Name</div>
                                    <div className='col-md-12 mb-2' >
                                        <Autocomplete
                                            id='PatientName'
                                            options={patientDropdown}
                                            value={formValues.patientName}
                                            onChange={(e, v: any) => { formValues.patientId = v; setFormValues({ ...formValues }); }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" />
                                            )}
                                            disabled
                                        />
                                    </div>
                                    <div className='col-md-3 mb-2' >StartTime</div>
                                    <div className='col-md-3 mb-2' >EndTime</div>
                                    <div className='col-md-6 mb-2'>Prescribed By</div>
                                    <div className='col-md-3 mb-2' >
                                        <TextField type="time"
                                            autoComplete="off"
                                            value={formValues.startTime}
                                            id="StartTime"
                                            style={{ border: '1px solid #c6c6c6', height: '57px', color: "#b6b1b1", backgroundColor: "#ffffff" }}
                                            placeholder=" " disabled
                                        />
                                    </div>
                                    <div className='col-md-3 mb-2' >
                                        <TextField type="time"
                                            autoComplete="off"
                                            value={formValues.endTime}
                                            id="EndTime"
                                            style={{ border: '1px solid #c6c6c6', height: '57px', color: "#b6b1b1", backgroundColor: "#ffffff" }}
                                            placeholder=" " disabled
                                        />
                                    </div>
                                    <div className='col-md-6 mb-2' >
                                        <Autocomplete
                                            id='Prescribedby'
                                            options={staffDropdown}
                                            getOptionLabel={(option) => `${option.name}`}
                                            value={staffDropdown?.find((staff: any) => staff.id === formValues.prescribedby) || null}
                                            onChange={(event, value) => {
                                                setFormValues({
                                                    ...formValues,
                                                    prescribedby: value ? value.id : null,
                                                    prescribedbyName: value ? `${value.name}` : ''
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" />
                                            )} disabled
                                        />
                                    </div>
                                    <div className='col-md-6 mb-2' >Patient Incharge</div>
                                    <div className='col-md-6 mb-2'></div>
                                    <div className='col-md-6 mb-2' >
                                        <Autocomplete
                                            id='PatientIncharge'
                                            options={staffDropdown}
                                            getOptionLabel={(option) => `${option.name}`}
                                            value={staffDropdown?.find((staff: any) => staff.id === formValues.patientIncharge) || null}
                                            onChange={(event, value) => {
                                                setFormValues({
                                                    ...formValues,
                                                    patientIncharge: value ? value.id : null,
                                                    patientInchargeName: value ? `${value.name}` : ''
                                                });
                                                setErrors({ ...errors, patientIncharge: false });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder='Select Patient Name' variant="outlined"
                                                    error={!!errors.patientIncharge}
                                                    helperText={errors.patientIncharge ? 'select Patient Incharge' : ''}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-md-6 mb-2' >
                                    </div>
                                    {shifts.length > 0 && shifts[0].shift && shifts[0].shift.length > 0 && shifts[0].shift[0].record && shifts[0].shift[0].record.length > 0 ? (
                                        shifts[0].shift[0].record.map((record: any, recordIndex: any) => (
                                            <>
                                                <div className="col-md-5">Select Staff Name</div>
                                                <div className="col-md-3">Start Time</div>
                                                <div className="col-md-3">End Time</div>
                                                <div className="col-md-1"></div>
                                                <div className="col-md-5 mb-2">
                                                    <Select
                                                        style={{ height: "57px", paddingRight: '8px' }}
                                                        className="form-select"
                                                        fullWidth
                                                        value={record.staffId}
                                                        onChange={(e) => handleRecordChange(e, recordIndex)}
                                                    >
                                                        {staffDropdown?.map((staff: any) => (
                                                            <MenuItem key={staff.id} value={staff.id}>
                                                                {staff.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <div>
                                                        <input
                                                            type="time"
                                                            autoComplete="off"
                                                            value={record.startTime}
                                                            onChange={(e) => handleInputChange(e, recordIndex, 'startTime')}
                                                            id="StartTime"
                                                            style={{ border: '1px solid #c6c6c6', height: '57px' }}
                                                            placeholder=" "
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <div>
                                                        <input
                                                            type="time"
                                                            autoComplete="off"
                                                            value={record.endTime}
                                                            onChange={(e) => handleInputChange(e, recordIndex, 'endTime')}
                                                            id="EndTime"
                                                            style={{ border: '1px solid #c6c6c6', height: '57px' }}
                                                            placeholder=" "
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mb-2" role="button">
                                                    {shifts[0].shift[0].record.length - 1 === recordIndex && (
                                                        <div>
                                                            <i
                                                                style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }}
                                                                onClick={addRecord}
                                                                className="material-icons"
                                                            >
                                                                add
                                                            </i>
                                                        </div>
                                                    )}
                                                    {shifts[0].shift[0].record.length !== 1 && (
                                                        <div>
                                                            <i
                                                                style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }}
                                                                onClick={() => confirmDeleteRecord(recordIndex)}
                                                                className="material-icons"
                                                            >
                                                                remove
                                                            </i>
                                                        </div>
                                                    )}
                                                </div>
                                                <Dialog open={confirmDelete} onClose={cancelDelete} style={{ backgroundColor: "#ffffff36" }}>
                                                    <DialogTitle>Confirm Delete</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            Are you sure you want to delete this Staff?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <div className="d-flex gap-3 justify-content-center">
                                                            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={cancelDelete} ></Button>
                                                            <Button label="Delete Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => removeRecord(recordIndex)}></Button>
                                                        </div>
                                                    </DialogActions>
                                                </Dialog>
                                            </>
                                        ))
                                    ) : (
                                        <div>No records available</div>
                                    )}
                                </div>
                            </ModalBody>
                        </div>
                        <ModalFooter className="">
                            <div className="d-flex gap-3 justify-content-center">
                                <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => closeModal(false)} ></Button>
                                <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={savePatients}></Button>
                            </div>
                        </ModalFooter>
                    </div>
                </div>
            </Modal >
        </div>
    )
}

export default EditOneToOneRoutineModal