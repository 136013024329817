import React, { useEffect, useState } from "react";
import Calendar from "../../components/calendar";
import './../organizationDetails/form.css'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import {
    Modal, ModalBody, ModalHeader
} from "reactstrap";
import { getOrgByID } from "../../slices/organization/thunk";
import { saveOrganization } from "../../slices/login/reducer";
import { HttpLogin } from "../../utils/Http";
import { baseURL, successCode } from "../../configuration/url";
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import { authorize } from "../../helpers/common";
import { getPatientDropdown, getStaffDropdown } from "../../slices/thunk";
import { Tooltip } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import localStorage from "redux-persist/es/storage";
import { grey, primarybg, primarytext } from "../../common/primary";
import AddOneToOne from "./AddOneToOne";
import EditOneToOneRoutineModal from "./editOneToOneRoutineModal";

type ShiftName = 'Shift-A' | 'Shift-B' | 'Shift-C';

const OneToOne = () => {
    const getCurrentFormattedDate = () => {
        const currentDate = new Date();
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
        return currentDate.toLocaleDateString('en-US', options);
    };
    const dispatch = useDispatch<any>()

    const { organization } = useSelector((state: any) => state.Login)
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [date, setDate] = useState(getCurrentFormattedDate)
    const [currentPage, setCurrentPage] = useState(0);
    const formatDate = (date: any) => {
        const options = { day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    const { patientDropdown } = useSelector((state: any) => state.Patient);



    const [selectedTab, setSelectedTab] = useState<ShiftName>('Shift-A');

    const handleDate = (date: any) => {
        setSelectedDate(date)
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        setDate(formattedDate)
    }
    const renderDateBoxes = () => {
        const dateBoxes = [];
        for (let i = -3; i <=3; i++) {
            const currentDate = new Date(selectedDate);
            currentDate.setDate(selectedDate.getDate() + i);
            const date = selectedDate.getDate().toString().padStart(2, '0');
            const year = selectedDate.getFullYear();
            const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
            window.localStorage.setItem("getByDate", `${year}${month}${date}`)
            dateBoxes.push(
                <Calendar
                    key={i}
                    day={currentDate.toLocaleDateString('en-US', { weekday: 'short' })}
                    date={formatDate(currentDate)}
                    onClick={() => handleDate(currentDate)}
                    isSelected={selectedDate?.toDateString() === currentDate.toDateString()}
                />
            );
        }
        return dateBoxes;
    };
    const initFormData = {
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        oneToOneObserverName: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: "",
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    }

    const date1 = selectedDate.getDate().toString().padStart(2, '0');
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const forDate = `${year}${month}${date1}`
    localStorage.setItem("Date", forDate);
    useEffect(() => {
        saveOrganization(dispatch)
        getPatientDropdown(dispatch, organization)
        getOrgByID(dispatch, organization)
    }, [dispatch, selectedDate])
    const [editModal1, setEditModal1] = useState(false);
    const [editModalview, setEditModalview] = useState(false);
    const [shifts, setShifts] = useState([
        {
            date: "",
            id: "",
            organization: "",
            pid: "",
            shift: [
                {
                    shiftName: "",
                    shiftTime: "",
                    shiftIncharge: "",
                    record: [
                        {
                            distance: "",
                            endTime: "",
                            note: [
                                {
                                    note: "",
                                    time: ""
                                }
                            ],
                            staffId: "",
                            staffName: "",
                            startTime: ""
                        }
                    ]
                }
            ]
        }
    ]);
    const [formValues, setFormValues] = useState({
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: '',
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    });

    const editChange = (patient: any) => {
        const { header1 } = authorize();
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        if (patient) {
            setFormValues({
                date: patient.date || '',
                endTime: patient.endTime || '',
                id: patient.id || '',
                oneToOneObserver: patient.oneToOneObserver || '',
                organization: patient.organization || '',
                patientId: patient.patientId || '',
                patientName: patient.patientName || '',
                patientIncharge: patient.shiftIncharge || '',
                patientInchargeName: patient.patientIncharge || '',
                prescribedby: patient.staffId || '',
                prescribedbyName: patient.staffName || '',
                startTime: patient.startTime || ''
            });
            setEditModal1(true);
            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${year}${month}${date}&organization=${organization}&shiftName=${selectedTab}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        setPatientDate(response.data.data)
                    } else {
                        setPatientDate([""]);
                    }
                })

            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOneByPidAndDateAndOrganizationAndShift?date=${forDate}&organization=${organization}&patientId=${patient.patientId}&shiftName=${selectedTab}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        const responseData = response.data.data;
                        const transformedData = [
                            {
                                date: forDate,
                                id: patient.id,
                                organization: organization,
                                pid: patient.patientId,
                                shift: [
                                    {
                                        shiftName: responseData.shiftName,
                                        shiftTime: responseData.shiftTime,
                                        shiftIncharge: responseData.shiftIncharge,
                                        record: responseData.record
                                    }
                                ]
                            }
                        ];
                        setShifts(transformedData);
                    } else {
                        setShifts([
                            {
                                date: "",
                                id: "",
                                organization: "",
                                pid: "",
                                shift: [{
                                    shiftName: "",
                                    shiftTime: "",
                                    shiftIncharge: "",
                                    record: [
                                        {
                                            distance: "",
                                            endTime: "",
                                            note: [{ note: "", time: "" }],
                                            staffId: "",
                                            staffName: "",
                                            startTime: "",

                                        }
                                    ]
                                }]
                            }
                        ]);
                    }
                });
        } else {
            console.error("Invalid patient data:", patient);
        }
    };
    const Viewstaff = (patient: any) => {
        const { header1 } = authorize();
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        if (patient) {
            setFormValues({
                date: patient.date || '',
                endTime: patient.endTime || '',
                id: patient.id || '',
                oneToOneObserver: patient.oneToOneObserver || '',
                organization: patient.organization || '',
                patientId: patient.patientId || '',
                patientName: patient.patientName || '',
                patientIncharge: patient.shiftIncharge || '',
                patientInchargeName: patient.patientIncharge || '',

                prescribedby: patient.staffId || '',
                prescribedbyName: patient.staffName || '',
                startTime: patient.startTime || ''
            });
            setEditModalview(true);
            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${year}${month}${date}&organization=${organization}&shiftName=${selectedTab}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        setPatientDate(response.data.data)
                    } else {
                        setPatientDate([""]);
                    }
                })
            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOneByPidAndDateAndOrganizationAndShift?date=${year}${month}${date}&organization=${organization}&patientId=${patient?.patientId}&shiftName=${selectedTab}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        const responseData = response.data.data;
                        const transformedData = [
                            {
                                date: forDate,
                                id: patient?.id,
                                organization: organization,
                                pid: patient?.patientId,
                                shift: [
                                    {
                                        shiftName: responseData.shiftName,
                                        shiftTime: responseData.shiftTime,
                                        shiftIncharge: responseData.shiftIncharge,
                                        record: responseData.record
                                    }
                                ]
                            }
                        ];
                        setShifts(transformedData);
                    } else {
                        setShifts([
                            {
                                date: "",
                                id: "",
                                organization: "",
                                pid: "",
                                shift: [{
                                    shiftName: "",
                                    shiftTime: "",
                                    shiftIncharge: "",
                                    record: [
                                        {
                                            distance: "",
                                            endTime: "",
                                            note: [{ note: "", time: "" }],
                                            staffId: "",
                                            staffName: "",
                                            startTime: "",

                                        }
                                    ]
                                }]
                            }
                        ]);
                    }
                });
        } else {
            console.error("Invalid patient data:", patient);
        }
    };

    useEffect(() => {
        saveOrganization(dispatch)
        getStaffDropdown(dispatch, organization);
        getOrgByID(dispatch, organization)
    }, [])

    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
        setErrors({});
    }

    let [PatientDate, setPatientDate] = useState<any[]>([{
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: '',
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    }])

    const fetchPatientDropdown = async () => {
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const { header1 } = authorize();
        HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${year}${month}${date}&organization=${organization}&shiftName=${selectedTab}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === successCode) {
                    setPatientDate(response.data.data)
                } else {
                    setPatientDate([""]);
                }
            })
    }
    useEffect(() => {
        fetchPatientDropdown();
    }, []);
    const handleTabClick = (tabId: any) => {
        setSelectedTab(tabId);
        setFormValues({
            date: tabId.date || '',
            endTime: tabId.endTime || '',
            id: tabId.id || '',
            oneToOneObserver: tabId.oneToOneObserver || '',
            organization: tabId.organization || '',
            patientId: tabId.patientId || '',
            patientName: tabId.patientName || '',
            patientIncharge: tabId.patientIncharge || '',
            patientInchargeName: tabId.patientIncharge || '',
            prescribedby: tabId.staffId || '',
            prescribedbyName: tabId.staffName || '',
            startTime: tabId.startTime || ''
        });
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const { header1 } = authorize();
        HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${year}${month}${date}&organization=${organization}&shiftName=${tabId}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === successCode) {
                    setPatientDate(response.data.data)
                } else {
                    setPatientDate([]);
                }
            })
        HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOneByPidAndDateAndOrganizationAndShift?date=${year}${month}${date}&organization=${organization}&patientId=${tabId?.patientId}&shiftName=${tabId}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === successCode) {
                    const responseData = response.data.data;
                    const transformedData = [
                        {
                            date: forDate,
                            id: tabId?.id,
                            organization: organization,
                            pid: tabId?.patientId,
                            shift: [
                                {
                                    shiftName: responseData.shiftName,
                                    shiftTime: responseData.shiftTime,
                                    shiftIncharge: responseData.shiftIncharge,
                                    record: responseData.record
                                }
                            ]
                        }
                    ];
                    setShifts(transformedData);
                } else {
                    setShifts([
                        {
                            date: "",
                            id: "",
                            organization: "",
                            pid: "",
                            shift: [{
                                shiftName: "",
                                shiftTime: "",
                                shiftIncharge: "",
                                record: [
                                    {
                                        distance: "",
                                        endTime: "",
                                        note: [{ note: "", time: "" }],
                                        staffId: "",
                                        staffName: "",
                                        startTime: "",

                                    }
                                ]
                            }]
                        }
                    ]);
                }
            });
    }
    const fetchPatientDropdownByDate = async (date: any, tab: any) => {
        const { header1 } = authorize();
        try {
            const response = await axios.get(`${baseURL}/oneToOne/oneToOnePatientList?date=${date}&organization=${organization}&shiftName=${tab}`, { headers: header1 });
            if (response.data.message.code === successCode) {
                setPatientDate(response.data.data);
            } else {
                setPatientDate([]);
                console.error('No Record:');
            }
        } catch (error) {
            setPatientDate([]);
            console.error('No Record:');
        }
    };

    const handleDateChange = (event: any) => {
        const newDate = new Date(event.target.value);
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
        const formattedDate = newDate.toLocaleDateString('en-US', options);
        setDate(formattedDate);
        setSelectedDate(newDate);
    };

    useEffect(() => {
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const currentDate = `${year}${month}${date}`;
        fetchPatientDropdownByDate(currentDate, selectedTab);
    }, [selectedDate, selectedTab]);
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                {renderDateBoxes()}
                <div className="inpMain">
                    <input
                        type="date"
                        value={selectedDate.toISOString().split('T')[0]}
                        onChange={handleDateChange}
                    />
                    <div style={{ border: "1px groove" }} />
                    <img style={{ position: "absolute", left: '12px', top: '21px', width: '32px', height: '22px' }} src={calendarMuiImage} />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", height: "79vh" }} >
                <div style={{ width: "15%", backgroundColor: "#eaf2fa", borderRight: "1px groove #ccd2d8" }} >
                    <div style={{ display: "flex", flexDirection: "column", height: "142px", justifyContent: "space-around", paddingTop: "20px", alignItems: "center" }}>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-A' ? 'active' : ''}`}
                            id="Shift-A-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-A"
                            aria-selected={selectedTab === 'Shift-A'}
                            onClick={() => handleTabClick('Shift-A')}
                            style={{ backgroundColor: selectedTab === 'Shift-A' ? '#cddcfa' : '', color: selectedTab === 'Shift-A' ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-A
                        </button>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-B' ? 'active' : ''}`}
                            id="Shift-B-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-B"
                            aria-selected={selectedTab === 'Shift-B'}
                            onClick={() => handleTabClick('Shift-B')}
                            style={{ backgroundColor: selectedTab === 'Shift-B' ? '#cddcfa' : '', color: selectedTab === 'Shift-B' ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-B
                        </button>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-C' ? 'active' : ''}`}
                            id="Shift-C-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-C"
                            aria-selected={selectedTab === 'Shift-C'}
                            onClick={() => handleTabClick('Shift-C')}
                            style={{ backgroundColor: selectedTab === 'Shift-C' ? '#cddcfa' : '', color: selectedTab === 'Shift-C' ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-C
                        </button>
                    </div>
                </div>
                <div style={{ width: "83%", backgroundColor: "#f2f5f9" }} >
                    <div style={{ textAlign: "start", borderBottom: "1px groove #ccd2d8", height: "70px", lineHeight: 3.5, padding: "3px 0px 0px 10px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ fontSize: "20px", fontWeight: 600 }}>{date + " "}{selectedTab} 1:1 Assignment </span>
                        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
                            <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: "pointer", fontSize: '30px', color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
                                <i style={{ fontSize: "36px", fontWeight: "lighter" }} className="material-icons" onClick={toggle}>add</i>
                            </div>
                            <div className="mx-0 search-container d-flex align-items-center">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="search form-control"
                                />
                                <FaSearch className="search-icon mt-1" />
                            </div>
                        </div>
                    </div>
                    <table className="table table-bordered" style={{ fontSize: '13px' }}>
                        <thead style={{ backgroundColor: grey }}>
                            <tr>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Patient Name</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Start Time</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>End Time</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Prescribed by</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Patient Incharge</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, borderRightStyle: "none" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {PatientDate && PatientDate.length > 0 ? (
                                PatientDate.map((patient: any, index: number) => (
                                    <tr key={index}>
                                        <td className="text text-center">{currentPage * 10 + index + 1}</td>
                                        <td
                                            style={{ cursor: "pointer", textAlign: "center" }}
                                            onClick={() => editChange(patient)}
                                            className="text"
                                        >
                                            {patient.patientName}
                                        </td>
                                        <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.startTime}</td>
                                        <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.endTime}</td>
                                        <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.staffName}</td>
                                        <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.shiftInchargeName}</td>
                                        <td className="text" style={{ display: "flex", justifyContent: "space-around", height: "35px", alignItems: "center", borderRightStyle: "none" }}>
                                        <Tooltip title="Edit" arrow>
                                        <div className="text-primary">
                                            <i
                                            style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                                            onClick={() => { setEditModal1(true); editChange(patient); }}
                                            className="material-icons">create</i>
                                        </div>
                                        </Tooltip>
                                        <Tooltip title="View" arrow>
                                        <div className="text-primary">
                                            <i
                                            style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                                            onClick={() => { setEditModalview(true); Viewstaff(patient); }}
                                            className="material-icons">preview</i>
                                        </div>
                                        </Tooltip>
                                        </td>
                                        
                                    </tr>
                                ))

                            ) : (
                                <tr>
                                    <td colSpan={8} className="text-center">There are no records....</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Modal isOpen={editModalview} centered size='lg'>
                        <div className="d-flex align-items-center justify-content-center vh-90">
                            <div className='row' style={{ width: "100%" }}>
                                <div className='container col-md-12'>
                                    <ModalHeader toggle={() => setEditModalview(false)}>
                                        <span>{formValues.patientName}</span>&nbsp;&nbsp;&nbsp;
                                        <span>{date + " "}</span>
                                    </ModalHeader>
                                    <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto', display: "flex", justifyContent: "center" }}>
                                        <table className="table table-bordered" style={{ fontSize: '13px' }}>
                                            <thead style={{ backgroundColor: grey }}>
                                                <tr>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Assigned Staff Name</th>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Start Time</th>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>End Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shifts.length > 0 && shifts[0].shift && shifts[0].shift.length > 0 && shifts[0].shift[0].record && shifts[0].shift[0].record.length > 0 ? (
                                                    shifts[0].shift[0].record.map((record: any, recordIndex: any) => (
                                                        <>
                                                            <tr
                                                            >
                                                                <td className="text text-center">{currentPage * 10 + recordIndex + 1}</td>
                                                                <td style={{ cursor: "pointer", textAlign: "center" }} className="text" >
                                                                    {record.staffName}
                                                                </td>
                                                                <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>
                                                                    {record.startTime}
                                                                </td>
                                                                <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{record.endTime}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={4} className="text-center">There are no records...</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </ModalBody>
                                </div>

                            </div>
                        </div >
                    </Modal >
                    <AddOneToOne 
                      IsOpen={modal} 
                      Close={toggle} 
                      errors={errors} 
                      setErrors={setErrors} 
                      setPatientDate={setPatientDate} 
                      selectedDate={selectedDate} 
                      selectedTab={selectedTab} 
                      shifts={shifts} 
                      forDate={forDate} 
                      patientDropdown={patientDropdown}
                    />
                    <EditOneToOneRoutineModal IsOpen={editModal1} closeModal={setEditModal1} formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors} shifts={shifts} setShifts={setShifts} forDate={forDate} editChange={editChange} selectedTab={selectedTab}  />

                </div>
            </div>
        </div >
    );
};
export default OneToOne;