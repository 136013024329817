import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "primereact/button";
import { Autocomplete, TextField } from "@mui/material";
import { authorize } from '../../helpers/common';
import { baseURL, successCode } from '../../configuration/url';
import axios from 'axios';
import { toast } from 'react-toastify';

interface SirenCreationProps {
  isOpen: boolean;
  CloseModal: () => void;
  orgID: string;
  sirenData?: any;
  dropdownData: { value: string; id: string }[];
}

interface registerInputData {
  virtualid: string | null;
  sirenName: string;
  location: string | null;
  id: string;
}

const SirenCreation: React.FC<SirenCreationProps> = ({ isOpen, CloseModal, orgID, sirenData, dropdownData }) => {
  const registerData = {
    virtualid: '',
    sirenName: '',
    location: null,
    id: ''
  };

  const [inputData, setInputData] = useState<registerInputData>(registerData);
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({});
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    if (sirenData) {
      console.log(sirenData)
      setInputData({
        virtualid: sirenData.virtualId,
        sirenName: sirenData.sirenName,
        id: sirenData.id,
        location: sirenData.location
      });
      const selectedOption = dropdownData.find((item) => item.value === sirenData.location);
      setValue(selectedOption || null);
    } else {
      setInputData(registerData);
      setValue(null);
    }
  }, [sirenData, dropdownData]);

  const handleSubmit = async () => {
    try {
      let newErrors = {
        sirenName: !inputData.sirenName,
        virtualid: !inputData.virtualid,
        location: !inputData.location
      };
      setNameError(newErrors);
      const hasErrors = Object.values(newErrors).some(error => error);
      if (hasErrors) {
        toast.error("Please Fill Required Field");
        return;
      }
      const body = {
        virtualId: inputData.virtualid,
        sirenName: inputData.sirenName,
        id: !sirenData ? "" : sirenData.id,
        location: inputData.location,
        organization: orgID
      };
      const response = await axios.post(`${baseURL}/siren/add`, body);
      if (response && response.data.message && response.data.message.code === successCode) {
        toast.success('Registered Successfully');
        setNameError({ virtualid: false, location: false, sirenName: false });
        CloseModal()
      }
    } catch (error) {
      console.error("API Error: ", error);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} size="lg" centered style={{ width: "580px" }}>
        <div className="d-flex align-items-center justify-content-center m-20">
          <div className="row w-100">
            <div className="container col-md-12">
              <ModalHeader toggle={() => {
                CloseModal();
                setNameError({ virtualid: false, location: false, sirenName: false });
              }}>
                {!sirenData ? 'Siren Register' : 'Update'}
              </ModalHeader>
              <ModalBody>
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center" }}>
                  {/* Additional Content */}
                </div>
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                  <div className="col-md-12 mb-2">
                    <TextField
                      label="Virtual Id"
                      variant="outlined"
                      fullWidth
                      value={inputData.virtualid}
                      onChange={(e) => {
                        setInputData({ ...inputData, virtualid: e.target.value });
                        setNameError({ ...nameError, virtualid: false });
                      }}
                      error={!!nameError.virtualid}
                    />
                  </div>
                </div>
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                  <div className="col-md-6 mb-2">
                    <TextField
                      label="Siren Name"
                      variant="outlined"
                      fullWidth
                      value={inputData.sirenName}
                      onChange={(e) => {
                        setInputData({ ...inputData, sirenName: e.target.value });
                        setNameError({ ...nameError, sirenName: false });
                      }}
                      error={!!nameError.sirenName}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Autocomplete
                      id="location-autocomplete"
                      options={dropdownData}
                      getOptionLabel={(option) => option.value}
                      value={value}
                      onChange={(e, v) => {
                        if (v) {
                          setValue(v);
                          setInputData({ ...inputData, location: v.value });
                          setNameError({ ...nameError, location: false });
                        } else {
                          setValue(null);
                          setInputData({ ...inputData, location: null });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Location"
                          fullWidth
                          error={!!nameError.location}
                        />
                      )}
                    />
                  </div>
                </div>
              </ModalBody>
            </div>
            <ModalFooter>
              <div className="d-flex gap-3 justify-content-center">
                <Button
                  label="Cancel"
                  severity="secondary"
                  style={{ color: "#000", backgroundColor: "#94a0b7", fontWeight: "bold" }}
                  onClick={() => {
                    CloseModal();
                    setNameError({ virtualid: false, location: false, sirenName: false });
                  }}
                />
                <Button
                  label="Save Changes"
                  style={{ backgroundColor: "#0f3995", fontWeight: "bold" }}
                  onClick={handleSubmit}
                />
              </div>
            </ModalFooter>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SirenCreation;