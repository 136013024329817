import React from 'react'
import { Dialog, DialogContent, DialogContentText, DialogTitle, ListItem, MenuItem, IconButton, Select, TextField, Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { List } from "reactstrap";
import { FaQrcode } from "react-icons/fa";
import { Bed } from "@mui/icons-material";
import PatientBed from "../AllPatient/patientBed";
import { Button } from "primereact/button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { authorize, formatDateToYYYYMMDD } from '../../helpers/common';
import { baseURL, successCode } from '../../configuration/url';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBed, getAllBedAssign, getAllPatient } from '../../slices/thunk';
import { toast } from 'react-toastify';

interface TransferModalProps {
  IsOpen: boolean
  dischargeTransferId: any
  Close: ()=> void
  handleQrClick: any
  patientname: any
  error: any
  setError: any
  admitDate: any
  deviceId: any
  setDeviceId: any
  setAdmitDate: any
  dateFieldError: string
  setDateFieldError: any
  reason: any
  setReason: any
  options: any
  bedClick: any
  bedSelected: any
  roomNo: any
  admitD: any
  setAdmitD: any
  bedId: any
  handleBedClick: any
  bedsByNumber: any
  currentPage: number
  setBedAssignDialog: any
  setBedClick: any
  selectPatientId: any
}

const TransferModal: React.FC<TransferModalProps> = ({IsOpen, dischargeTransferId, Close, handleQrClick,patientname, setError, admitDate, deviceId, setDeviceId, setAdmitDate, dateFieldError, setDateFieldError, error, reason, setReason, options, bedClick, bedSelected, roomNo, admitD, setAdmitD, bedId, handleBedClick, bedsByNumber, currentPage, setBedAssignDialog, setBedClick, selectPatientId}) => {
  const dispatch = useDispatch<any>();
  const { organization, userData } = useSelector((state: any) => state.Login);
  const userId = userData?.userDetail.id;
  
  const handleSave = async () => {
    if (!admitDate) {
      setDateFieldError('Date field cannot be empty.');
      return;
    }
    const { header1 } = authorize();
    try {
      const formattedDate = formatDateToYYYYMMDD(admitDate);
      const requestBody = {
        bedId: bedId,
        pid: selectPatientId,
        transferedBy: userId,
        transferDate: formattedDate,
        transferReason: reason,
        deviceId: deviceId
      };
      const requestBody1 = {
        roomBed: roomNo,
        pid: selectPatientId,
        orgId: organization,
        dischargeDate: formattedDate,
        dischargedBy: userId,
        dischargeReason: reason
      }
      const response = await axios.post(
        `${baseURL}/Q15Bed/${dischargeTransferId === '1' ? 'transfer' : 'dischargePatient'}`,
        dischargeTransferId === '1' ? requestBody : requestBody1, { headers: header1 }
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) {
        Close()
        getAllPatient(dispatch, organization, currentPage, '');
        getAllBedAssign(dispatch, organization);
        getAllBed(dispatch, organization);
        toast.success(response.data.message.description)
        setBedAssignDialog(false)
        setBedClick(false)
        setAdmitD(false)
      } else {
        console.error("Error:", response.data.message);
      }
    } catch (error) {
      console.error("API Request Error:", error);
    }
  }

  
  const errorMessage = React.useMemo(() => {
      if (dateFieldError) return dateFieldError;
      switch (error) {
        case 'maxDate':
        case 'minDate':
          return 'Please select a date in the first quarter of 2022';
        case 'invalidDate':
          return 'Your date is not valid';
        default:
          return '';
      }
  }, [error, dateFieldError]);
  return (
    <>
     <div>
     <Dialog maxWidth={'xl'} PaperProps={{ sx: { width: dischargeTransferId === "1" ? '67%' : '40%', maxWidth: '72%', position: 'absolute',  height: '525px', top: '40px', overflow: 'hidden' } }}
        open={IsOpen}
      >
        <DialogTitle style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div>
            {dischargeTransferId === "1" ? 'Transfer' : 'Discharge'}
          </div>
          <div>
          <button type="button" className="btn-close" aria-label="Close" onClick={Close}></button>
          </div>
        </DialogTitle>
        <DialogContentText >
          <DialogContent style={{ padding: '20px', background: '#F8FAFB', overflowY: 'auto', height: '400px' }}>
            <div>
              <>
                <div className="row w-100 mb-3">
                  <div className="col-md-12">
                    <TextField id="Reason" label="Patient Name" variant="outlined" value={patientname} fullWidth />
                  </div>
                </div>
                <div className="row w-100 ">
                  <div className='col-md-6 mb-2' >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box >
                        <DatePicker
                          sx={{ width: "100%" }}
                          defaultValue={dayjs('2022-07-17')}
                          onError={(newError) => setError(newError)}
                          label={dischargeTransferId === "1" ? 'Transfer Date' : 'Discharge Date'}
                          value={admitDate}
                          onChange={(newValue) => {
                            setAdmitDate(newValue);
                            if (dateFieldError) setDateFieldError('')
                          }}
                          slotProps={{
                            textField: {
                              helperText: errorMessage,
                              error: Boolean(dateFieldError || error),
                            },
                          }}
                        />
                      </Box>
                    </LocalizationProvider>
                  </div>
                  <div className='col-md-6 mb-2'>
                    <TextField id="Reason" label={dischargeTransferId === "1" ? 'Transfer Reason' : 'Discharge Reason'} variant="outlined" value={reason} fullWidth onChange={(e) => setReason(e.target.value)} />
                  </div>
                  <div className="row w-100 ">
                    <div className='col-md-6 mb-2' style={{ textAlign: 'left' }}>{dischargeTransferId === "1" && "Device-Id"}</div>
                    <div className='col-md-3 mb-2' ></div>
                    <div className='col-md-3 mb-2' ></div>
                  </div>
                  {dischargeTransferId === "1" && <>
                    <div className='col-md-6 mb-2'>
                      <List sx={{ pt: 0 }} style={{ margin: 0, padding: 0 }}>
                        <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
                          <Select
                            // multiple
                            value={deviceId}
                            onChange={(e) => setDeviceId(e.target.value)}
                            style={{ minHeight: '50px', width: '100%', marginLeft: '12px' }}
                            IconComponent={() => (
                              <FaQrcode
                                className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                                role='button'
                                style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0 }}
                              />
                            )}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: '300px',
                                },
                              },
                            }}
                          >
                            {options?.map((option: any) => (
                              <MenuItem key={option.id} value={option.deviceId}>
                                {option.deviceId}
                              </MenuItem>
                            ))}
                          </Select>
                        </ListItem>
                      </List>
                    </div>
                    <div className="col-md-6 mb-2" style={{ textAlign: 'end' }}>
                      <TextField
                        label="Room-Bed"
                        type="text"
                        placeholder=''
                        margin="none"
                        fullWidth
                        value={bedClick && bedSelected ? bedSelected : roomNo}
                        disabled={true}
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={() => {
                              if (admitD === false) {
                                setAdmitD(true);
                              } else {
                                setAdmitD(false);
                              }
                            }} edge="end">
                              <Bed />
                            </IconButton>
                          )
                        }}
                      />
                    </div></>}
                </div>
              </>
              { admitD && <>
                <PatientBed
                  admitD={admitD} 
                  bedId={bedId}
                  handleBedClick={handleBedClick}
                  bedsByNumber={bedsByNumber}
                 />
                </> }
            </div>
          </DialogContent>
        </DialogContentText>
        <div className="d-flex gap-3 pt-3 mb-3 justify-content-center">
          <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }}
            onClick={Close}></Button>
          <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSave}></Button>
        </div>
      </Dialog>
     </div>
    </>
  )
}

export default TransferModal