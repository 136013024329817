import { CircularProgress } from '@material-ui/core';
import { Autocomplete, List, ListItem, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from "primereact/button";
import uploadImage from "../../assets/images/rectangle-59991.svg";
import { HttpLogin } from '../../utils/Http';
import { authorize } from '../../helpers/common';
import { successCode } from '../../configuration/url';
import axios from 'axios';
import * as Constants from "../Constants/Constant";
import { useDispatch, useSelector } from 'react-redux';
import { updatePatientDetails } from '../../slices/thunk';
import './newPatient.css'
import "../bedAssign/bedassign.css";
import { FaQrcode } from 'react-icons/fa';

interface AdmitPatientEditModalProps {
  isOpen: any
  Close: any
  patientname: any
  formData: any
  setFormData: any
  deviceId: any
  setDeviceId: any
  options: any
  handleQrClick: any
  selectPatientId: any
  currentPage: any
}

const AdmitPatientEditModal: React.FC<AdmitPatientEditModalProps> = ({isOpen, Close, patientname, formData, setFormData, deviceId, selectPatientId, currentPage,handleQrClick, options, setDeviceId}) => {

  let [genderDropDown, setGenderDropDown] = useState(new Array<any>());
  let [cityDropDown, setCityDropDown] = useState(new Array<any>());
  const { organization } = useSelector((state: any) => state.Login);
  const dispatch = useDispatch<any>()   
    useEffect(() => {
      const fetchDropdownData = () => {
        const { header1 } = authorize();
        HttpLogin.axios().get(`/api/dropdowns/getByDropdown?dropdown=gender`, { headers: header1 })
          .then((response) => {
            if (response.data.data !== null && response.data.data !== successCode) {
              setGenderDropDown(response.data.data[0].list);
            }
          })
      }
      fetchDropdownData();
    }, []);
  
    const hiddenFileInput = useRef<any>(null);

    const handleProfileChange = () => {
      if (hiddenFileInput !== null) {
        hiddenFileInput.current.click();
      }
    }

    const handleImageChange = (e: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        formData.profile = reader.result !== null ? reader.result.toString() : ""
        setFormData({ ...formData });
      }
      reader.readAsDataURL(e.target.files[0]);
    }

    const [loading1, setLoading] = useState(false);
    const handlePostalCodeChange = async (e: any) => {
      formData.postalCode = e.target.value;
      setFormData({ ...formData });
      if (e.target.value.length === 5) {
        setLoading(true);
        try {
          const response = await axios.get(`https://app.zipcodebase.com/api/v1/search?codes=${e.target.value}&country=US&${Constants.apiKey}`);
          formData.state = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].state : "";
          formData.country = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].country_code : "";
          formData.city = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].city : "";
          setCityDropDown(response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]]?.map((k: any) => k.city_en) : [])
        } catch (error) {
          console.error('Error fetching city:', error);
        } finally {
          setLoading(false);
        }
      } else {
        formData.state = "";
        formData.country = "";
        formData.city = "";
        setLoading(false);
      }
      setFormData({ ...formData });
    };

    const handleAddress = (e: any) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleinputchange = (event: any) => {
      if (event.target.id === 'firstName') {
        formData.firstName = event.target.value;
      } else if (event.target.id === 'middleName') {
        formData.middleName = event.target.value;
      } else if (event.target.id === 'lastName') {
        formData.lastName = event.target.value;
      } else if (event.target.id === 'mrNumber') {
        formData.mrNumber = event.target.value;
      } else if (event.target.id === 'ssn') {
        formData.ssn = event.target.value;
      } else if (event.target.id === 'email') {
        formData.email = event.target.value;
      } else if (event.target.id === 'addressLine1') {
        formData.addressLine1 = event.target.value;
      } else if (event.target.id === 'AddressLine2') {
        formData.addressLine2 = event.target.value;
      } else if (event.target.id === 'city') {
        formData.city = event.target.value;
      } else if (event.target.id === 'state') {
        formData.state = event.target.value;
      } else if (event.target.id === 'country') {
        formData.country = event.target.value;
      }
      setFormData({ ...formData });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData((prevData: object) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const handleSaveChanges = () => {
      if (!selectPatientId) {
        return;
      } else if (formData.postalCode.length > 0 && formData.city === "" && formData.state === "") {
        formData.city = "";
        formData.state = "";
        formData.country = "";
        alert("Please Enter Valid Zip Code");
        return;
      }
      const formatDateold = (dateString: any) => {
        const [year, month, day] = dateString.split('-');
        return `${year}${month}${day}`;
      };
      const updatedPatientFields = {
        id: selectPatientId,
        basicDetails: [
          {
            name: [
              {
                use: formData.middleName,
                given: formData.firstName,
                family: formData.lastName,
              },
            ],
            profile: formData.profile,
            ssn: formData.ssn,
            mrNumber: formData.mrNumber,
            gender: formData.gender,
            birthDate: formatDateold(formData.birthDate),
          },
        ],
        email: formData.email,
        organization,
        contact: [
          {
            address: [
              {
                addressLine1: formData.addressLine1,
                addressLine2: formData.addressLine2,
                city: formData.city,
                state: formData.state,
                postalCode: formData.postalCode,
                country: formData.country,
              },
            ],
          },
        ],
        deviceId: deviceId,
      };
      dispatch(
        updatePatientDetails(
          selectPatientId,
          updatedPatientFields,
          organization,
          currentPage
        )
      );
      Close(false);
    };
  return (
    <><div>
    <Modal
     isOpen={isOpen}
     // toggle={() => setEditModal(false)}
     centered
     size="lg"
   ><div className="d-flex align-items-center justify-content-center vh-90">
       <div className="row">
         <div className="container col-md-12">
              <ModalHeader toggle={() => Close(false)}>{patientname}<span>
                <div onClick={handleProfileChange} hidden={formData.profile !== null && formData.profile !== "" && formData.profile !== "string"} style={{ left: "unset", width: "156px" }} className="vector-group">
                  <img className="frame-child4" style={{ cursor: 'pointer' }} alt="" src={uploadImage} />
                  <div className="upload-profile-pic1" style={{ fontSize: '13px', color: 'blue', cursor: 'pointer' }}>UPLOAD PROFILE PIC</div>
                </div>
                <div hidden={formData.profile === null || formData.profile === "" || formData.profile === "string"} style={{ left: "unset", width: "182px" }} className="vector-group">
                  <div style={{ position: 'absolute', top: "2px" }}><img style={{ height: '50px', width: '50px', borderRadius: "60px" }} alt='' src={formData.profile} onClick={handleProfileChange} /><input className="vector-group" style={{ position: 'absolute', top: '12px', display: 'none' }} type="file" ref={hiddenFileInput} onChange={handleImageChange} /></div>
                </div>
              </span></ModalHeader>
           <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
             <div className="row w-100 ">
               <div className="col-md-4 "  >
                 <TextField
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    placeholder="Enter First Name"
                    value={formData.firstName}
                    onChange={handleinputchange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                 />
               </div>
               <div className="col-md-4 ">
                 <TextField
                    id="middleName"
                    name="middleName"
                    label="Middle Name"
                    placeholder="Enter Middle Name"
                    value={formData.middleName}
                    onChange={handleinputchange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                 />
               </div>
               <div className="col-md-4 ">
                 <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    placeholder="Enter LastName"
                    value={formData.lastName}
                    onChange={handleinputchange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="medium"
                 />
               </div>
             </div>
             <div className="row w-100">
               <div className="col-md-4 mt-3">
               <Autocomplete
                  id="natureOfReaction"
                  options={genderDropDown?.map((item: any) => item.value)}
                  value={formData.gender}
                  onChange={(e, v) => { formData.gender = v; setFormData({ ...formData }); }}
                  sx={{ width: "100%" }}
                  size="medium"
                  renderInput={params =>
                    <TextField
                      name="natureOfReaction"
                      {...params}
                      variant="outlined"
                      label="Gender"
                      placeholder="Nature of Reaction"
                      margin="none"
                      size="medium"
                      fullWidth
                    />
                  }
                    />
               </div>
               <div className="col-md-4 ">
                 <TextField
                      type="date"
                      id="birthDate"
                      name="birthDate"
                      label="Date Of Birth"
                      placeholder="Enter DateOfBirth"
                      value={formData.birthDate}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                 />
               </div>
               <div className="col-md-4 ">
                 <TextField
                    id="mrNumber"
                    name="mrNumber"
                    label="MRN#"
                    value={formData.mrNumber}
                    onChange={handleinputchange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                 />
               </div>
             </div>
             <div className="row w-100">
               <div className="col-md-6 ">
                 <TextField
                    id="email"
                    name="email"
                    label="Email"
                    placeholder="Enter Email"
                    value={formData.email}
                    onChange={handleinputchange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                 />
               </div>
               <div className="col-md-6 ">
                 <TextField
                    id="ssn"
                    name="ssn"
                    label="SSN"
                    placeholder="Enter SSN"
                    value={formData.ssn}
                    onChange={handleinputchange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                 />
               </div>
             </div>
             <div className="row w-100">
               <div className="col-md-4 ">
                 <TextField
                    id="addressLine1"
                    name="addressLine1"
                    label="Address Line 1"
                    placeholder="Enter Address"
                    value={formData.addressLine1}
                    onChange={handleinputchange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                 />
               </div>
               <div className="col-md-4 ">
                 <TextField
                    id="addressLine2"
                    name="addressLine2"
                    label="Address Line 2"
                    placeholder="Enter Address"
                    value={formData.addressLine2}
                    onChange={handleAddress}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                 />
               </div>
               <div className="col-md-4 mt-3">
                 <TextField
                    id="zipcode"
                    label="Zip/Postal Code"
                    variant="outlined"
                    fullWidth
                    value={formData.postalCode}
                    onChange={handlePostalCodeChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 5,
                      pattern: "\\d{5}",
                    }}
                 />
                    {loading1 && <CircularProgress size={'25px'} />}
               </div>
             </div>
             <div className="row w-100">
               <div className="col-md-4 mt-3">
                 {cityDropDown !== null && cityDropDown.length === 1 ?
                   <TextField
                     id="city"
                     label="City"
                     variant="outlined"
                     fullWidth
                     value={formData.city}
                     onChange={handleinputchange}
                     InputLabelProps={{
                       shrink: true,
                     }}
                   /> :
                   <Autocomplete
                     id="city"
                     options={cityDropDown}
                     value={formData.city}
                     getOptionLabel={(option) => option}
                     onChange={(e, v) => { formData.city = v; setFormData({ ...formData }); }}
                     sx={{ width: "100%" }}
                     size="medium"
                     renderInput={params =>
                       <TextField
                         name=""
                         {...params}
                         variant="outlined"
                         label="City"
                         placeholder=""
                         margin="none"
                         size="medium"
                         fullWidth
                       />
                     }
                   />}
               </div>
               <div className="col-md-4 mt-3">
                 <TextField
                    id="state"
                    label="State"
                    variant="outlined"
                    fullWidth
                    value={formData.state}
                    onChange={handleinputchange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: "100%" }}
                 />
               </div>
               <div className="col-md-4">
                 <TextField
                    id="country"
                    name="country"
                    label="Country"
                    placeholder="Enter Country"
                    value={formData.country}
                    onChange={handleinputchange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                 />
               </div>
             </div>
             
             <div className="row w-100 d-flex">
                  <p>Device Id</p>
                  <div className='col-md-6' style={{ position: 'relative', paddingLeft: 0, padding: 0 }}>
                    <List sx={{ pt: 0 }} style={{ margin: 0, padding: 0 }}>
                      <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
                        <Select
                          // multiple
                          value={deviceId}
                          onChange={(e) => setDeviceId(e.target.value)}
                          style={{ minHeight: '50px', width: '300px', marginLeft: '12px' }}
                          IconComponent={() => (
                            <FaQrcode
                              className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                              role='button'
                              style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0 }}
                            />
                          )}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '300px',
                              },
                            },
                          }}
                        >
                          {options?.map((option: any) => (
                            <MenuItem key={option.id} value={option.deviceId}>
                              {option.deviceId}
                            </MenuItem>
                          ))}
                        </Select>
                      </ListItem>
                    </List>
                  </div>
                </div>
           </ModalBody>
         </div>
       </div>
     </div>
     <ModalFooter className="">
       <div className="d-flex gap-3 justify-content-center">
         <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => Close(false)}></Button>
         <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSaveChanges}></Button>
       </div>
     </ModalFooter>
   </Modal>
 </div>
      
    </>
  )
}

export default AdmitPatientEditModal