import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    useEffect(()=>{
        if (!jwt) {
            navigate('/')
        }
    },[])
  return (
    <div className={`${jwt && 'position-absolute'} `}>404Page</div>
  )
}

export default PageNotFound