import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import axios from 'axios';
import { Button, Table } from 'reactstrap';
import moment from 'moment';
import { getAllPatient } from '../../slices/thunk';
import printImage from '../../assets/images/printer.svg'
import { secondarybt } from "../../common/primary";
import { toast } from 'react-toastify';
import { primarytext, grey } from "../../common/primary";
// import { jsPDF } from 'jspdf';
import MultipleReportView from './MultipleReportView';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'

const OneToOneReportView = () => {
  const addInputData = {
    date: '' ,
    patientName: ''
  }
  const pdfRef = useRef<any>()
  const org = useSelector((state: any) => state.Login.organization)
  const { patientData } = useSelector((state: any) => state.Patient);
  let [datas, setData] = useState(new Array<any>());
  let [selectdate, setSelectDate] = useState<any>(new Array<any>());
  let [dates, setDates] = useState<any>();
  let [addPatientName, setAddPatientName] = useState("");
  let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
  const [viewContentData, setViewContent] = useState(true);
  const [newInputData, setNewInputData] = useState(addInputData)
  const [value, setValue] = useState<any>()
  const [disabled, setDisabled] = useState<boolean>(false);
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const [selectShift, setSelectShift] = useState<any>('');
  const [selectShiftView, setSelectShiftView] = useState<boolean>(false);
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
  let [outputSave, setOutputSave] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [accordionView, setAccordionView] = useState<boolean>(false)
  const [checkSelect, setCheckSelect] = useState<number>(1)
  const [multiReportData, setMultiReportData] = useState<any>()
  const [reportData, setReportData] = useState<any>()
  const [singleDate, setSingleDate] = useState<any>()

  const dispatch = useDispatch<any>()
  const option = ['Shift-A', 'Shift-B', 'Shift-C']

  useEffect(() => {
    getAllPatient(dispatch, org, -1, '');
  }, [org])
  useEffect(() => {
    setAddNewPatient(patientData !== null && patientData !== undefined && patientData);
    setData(patientData !== null && patientData !== undefined && patientData?.map((k: any) => { return k.basicDetails[0].name[0].given + " " + k.basicDetails[0].name[0].family }))
  }, [patientData])

  useEffect(() => {
    if (!value) return
    setNewInputData({ ...newInputData, date: moment(value).format("YYYYMMDD") });
  }, [value])

  useEffect(() => {
    console.log(newInputData.patientName, '1')
    const getDropDownDate = async () => {
      try {
        const response = await axios.get(`${baseURL}/register1to1/getDateList?patientId=${newInputData.patientName}`)
        if (response) {
          setSelectDate(response.data.data)
        } else {
          console.error("Error");
          setSelectDate([]);
        }
      } catch (error) {
        console.error("Error:", error);
        setSelectDate([]);
      }
    }
    getDropDownDate();
  }, [newInputData, addPatientName]);

  useEffect(() => {
    setDates(selectdate ? selectdate?.map((item: any, inx: number) => { return  item.date }) : []);
    if (selectdate === null || selectdate === undefined) {
      setValue('')
    }
  }, [selectdate, addPatientName, newInputData]);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(e.target.checked);
  };
  const handlesumbit = async () => {
     if (checkSelect === 1) {
       let newErrors = {
         patientName: !newInputData.patientName,
         date: !newInputData.date
       };
       setNameError(newErrors);
       const hasErrors = Object.values(newErrors)?.some(error => error);
       if (hasErrors) {
         toast.error("Please Fill Required Field")
         return;
       }
     }
      try {
        
        setMultiReportData([])
        setReportData([])
        let bodyDate:any[];
        if (checkSelect === 1) {
          bodyDate = singleDate
        } else {
          bodyDate = selectedRows
        }
        // let apidate = ''
        // const spreddate: any[] = selectedRows && selectedRows.map((item: any, inx: number) => {
        //   if ((inx + 1) < selectedRows.length) {
        //     apidate = apidate + `dates=${moment(item).format("YYYYMMDD")}` + '&'
        //   } else {
        //     apidate = apidate + `dates=${moment(item).format("YYYYMMDD")}`
        //   }
        // })
        // const response = await axios.get(`${baseURL}/register1to1/getOneToOneConfigReportForMultipleDate?${apidate}&organization=${org}&patientId=${newInputData.patientName}&shiftName=${selectShift}`)
        const response = await axios.post(`${baseURL}/register1to1/getOneToOneConfigReportForMultipleDate?organization=${org}&patientId=${newInputData.patientName}&shiftName=${selectShift}`, {dates:bodyDate})
        if (response?.data.message && response?.data.message.code === successCode) {
          if (checkSelect === 1) {
              console.log(response)
              setReportData(response.data.data)
              setSelectShiftView(selectShift ? true : false)
              setCheckBox(disabled)
          } else {
            setAccordionView(true)
            console.log('res', response)
            setMultiReportData(response.data.data)
            setReportData(response.data.data)
            setSelectShiftView(selectShift ? true : false)
            setCheckBox(disabled)
          }
        } else {
          console.error("Error:", response.data.message);
        }
        bodyDate = []
      } catch (error: any) {
        console.error("API Request Error", error)
      }
  }
  const handlePrint = () => {
      let printContents = document.getElementById('1to1AllReport')!.innerHTML;
      let printWindow = window.open('', '_blank');
      printWindow!.document.body.innerHTML = printContents;
      printWindow!.print();
      printWindow!.close();
  }
  const handleCheckSelect = (n: any) => {
    setCheckSelect(n)
    if (n === 1) {
      setAccordionView(false)
      setSelectedRows([])
      setReportData([])
    } else {
      setOutputSave(false)
      setSelectedRows([])
      setReportData([])
    }
  }

  // const handleDownload = () => {
  //   if (reportData) {
  //     reportData.forEach((report:any) => {
  //       const sections = document.querySelectorAll(`.report-section${moment(report.date).format("DD-MM-YYYY")}`);
  //       const pdf = new jsPDF('p', 'mm', 'a4', true);
  
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = pdf.internal.pageSize.getHeight();
  //       const margin = 20; // Define margin for left/right/bottom
  //       const contentWidth = pdfWidth - 2 * margin; // Adjust content width with margins
  //       const contentHeight = pdfHeight - margin; // Adjust content height with bottom margin
  
  //       const addSectionToPDF = (section:any) => {
  //         return new Promise((resolve, reject) => {
  //           // Debugging
  //           console.log('Section to capture:', section);
        
  //           setTimeout(() => {
  //             html2canvas(section, { useCORS: true, logging: true, scale: 2 })
  //               .then((canvas) => {
  //                 const imgData = canvas.toDataURL('image/png');
  //                 console.log('Captured image data:', imgData); // Log image data for debugging
        
  //                 if (!imgData || !imgData.startsWith('data:image/png')) {
  //                   console.error('Captured image is not valid PNG:', imgData);
  //                   return reject(new Error('Captured image is not a valid PNG.'));
  //                 }
        
  //                 // Continue with the rest of your logic...
  //                 // ...
  //               })
  //               .catch((error) => {
  //                 console.error('html2canvas error:', error);
  //                 reject(error);
  //               });
  //           }, 500); // Increase delay to 500ms or more
  //         });
  //       };
  
  //       const promises = Array.from(sections).map(addSectionToPDF);
  
  //       Promise.all(promises).then(() => {
  //         pdf.save(`${report.patientId}-${moment(report.date).format("DD-MM-YYYY")}.pdf`);
  //       }).catch((error) => {
  //         console.error('Error generating PDF:', error);
  //       });
  //     });
  //   }
  // };


  // const handleDownload = () => {
  //   if (reportData) {
  //     reportData.forEach((report:any) => {
  //       const sections = document.querySelectorAll(`.report-section${moment(report.date).format("DD-MM-YYYY")}`); // Adjust the selector to match your sections
  //       const pdf = new jsPDF('p', 'mm', 'a4', true); // Create PDF instance
      
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = pdf.internal.pageSize.getHeight();
      
  //       const margin = 20; // Define margin for left/right/bottom
  //       const contentWidth = pdfWidth - 2 * margin; // Adjust content width with margins
  //       const contentHeight = pdfHeight - margin; // Adjust content height with bottom margin
      
  //       const addSectionToPDF = (section:any) => {
  //         return new Promise<void>((resolve) => {
  //           html2canvas(section).then((canvas) => {
  //             const imgData = canvas.toDataURL('image/png');
  //             const imgWidth = canvas.width;
  //             const imgHeight = canvas.height;
      
  //             const ratio = Math.min(contentWidth / imgWidth, contentHeight / imgHeight);
  //             const imgX = margin; // Set X position to the left margin
  //             const imgY = margin; // Set Y position to the top margin
      
  //             // Add image to PDF
  //             pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      
  //             // Check if there are more sections to add
  //             if (section.nextElementSibling) {
  //               pdf.addPage(); // Add a new page if there are more sections
  //             }
              
  //             resolve(); // Resolve the promise
  //           });
  //         });
  //       };
      
  //       const promises = Array.from(sections).map(addSectionToPDF);
      
  //       Promise.all(promises).then(() => {
  //         pdf.save('report.pdf'); // Save the PDF after all sections are added
  //       });
        
  //     })
  //   }
  // };
 
  const handleDownload = async () => {
    if (reportData) {
      let index = 0;
      // for (const report of reportData) {
        // alert('in')
        // index++
        let myVar = setInterval(()=>{
          if (index < reportData.length) {
            const currentIndex = index
            // alert('yep')
            setTimeout(() => {timeDealys(reportData[currentIndex])}, 7000)            
            index++
          } else {
            // alert('nope')
            clearInterval(myVar)
          }
        }, 7000)
        async function timeDealys (reports:any) {
          // alert('1')
          const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`); // Adjust the selector to match your sections
        const pdf = new jsPDF('p', 'mm', 'a4', true); // Create PDF instance
  
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
  
        const margin = 20; // Define margin for left/right/bottom
        const contentWidth = pdfWidth - 2 * margin; // Adjust content width with margins
        const contentHeight = pdfHeight - margin; // Adjust content height with bottom margin
  
        const addSectionToPDF = (section: any) => {
          return new Promise<void>((resolve) => {
            html2canvas(section, {
              useCORS: true,
              logging: true,
              scale: 2,
              backgroundColor: null,
            }).then((canvas) => {
              console.log('Canvas:', canvas);
              const imgData = canvas.toDataURL('image/png');
              if (imgData.startsWith('data:image/png;base64,')) {
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
        
                const ratio = Math.min(contentWidth / imgWidth, contentHeight / imgHeight);
                const imgX = margin;
                const imgY = margin;
        
                pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
                
                if (section.nextElementSibling) {
                  pdf.addPage();
                }
                resolve();
              } else {
                console.error('Image data is invalid or empty', imgData);
                resolve();
              }
            }).catch((error) => {
              console.error('Error rendering section', error);
              resolve();
            });
          });
        };
        
        const promises = Array.from(sections).map(addSectionToPDF);
  
        // Wait for all sections to be processed before saving the PDF
        await Promise.all(promises);
        
        // Save the PDF after all sections are added
        pdf.save(`report-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);

        }
        // const sections = document.querySelectorAll(`.report-section${moment(report.date).format("DD-MM-YYYY")}`); // Adjust the selector to match your sections
        // const pdf = new jsPDF('p', 'mm', 'a4', true); // Create PDF instance
  
        // const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = pdf.internal.pageSize.getHeight();
  
        // const margin = 20; // Define margin for left/right/bottom
        // const contentWidth = pdfWidth - 2 * margin; // Adjust content width with margins
        // const contentHeight = pdfHeight - margin; // Adjust content height with bottom margin
  
        // const addSectionToPDF = (section: any) => {
        //   return new Promise<void>((resolve) => {
        //     html2canvas(section, {
        //       useCORS: true,
        //       logging: true,
        //       scale: 2,
        //       backgroundColor: null,
        //     }).then((canvas) => {
        //       console.log('Canvas:', canvas);
        //       const imgData = canvas.toDataURL('image/png');
        //       if (imgData.startsWith('data:image/png;base64,')) {
        //         const imgWidth = canvas.width;
        //         const imgHeight = canvas.height;
        
        //         const ratio = Math.min(contentWidth / imgWidth, contentHeight / imgHeight);
        //         const imgX = margin;
        //         const imgY = margin;
        
        //         pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
                
        //         if (section.nextElementSibling) {
        //           pdf.addPage();
        //         }
        //         resolve();
        //       } else {
        //         console.error('Image data is invalid or empty', imgData);
        //         resolve();
        //       }
        //     }).catch((error) => {
        //       console.error('Error rendering section', error);
        //       resolve();
        //     });
        //   });
        // };
        
        
  
        // const promises = Array.from(sections).map(addSectionToPDF);
  
        // // Wait for all sections to be processed before saving the PDF
        // await Promise.all(promises);
        
        // // Save the PDF after all sections are added
        // pdf.save(`report-${moment(report.date).format("DD-MM-YYYY")}.pdf`);
      // }
    }
  };
  const handleRowSelected = (item: string) => {
    const index = selectedRows.findIndex(selectedItem => selectedItem === moment(item).format("YYYYMMDD"));
    if (index > -1) {
      setSelectedRows(selectedRows.filter((_, i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, moment(item).format("YYYYMMDD")]);
    }
  };
  useEffect(() => {
    console.log(selectedRows,'selectedRow')
  }, [selectedRows])
  return (
    <div className='container-fluid overflow-y-auto'>
      <div className='pt-2'>
        <div className="d-flex justify-content-between">
          <div className="col-md-3">
            <h5 style={{ float: 'left' }}>One To One Report</h5>
          </div>
          <div className="col-md-2 d-flex justify-content-end ">
            <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px' }} onClick={handleDownload} >Download</button>
            <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} onClick={handlePrint}><img src={printImage} />Print</button>
          </div>
        </div>
        <div className=''>
          {/* Report View Header Start */}
          <div className=" d-flex flex-row mt-2" style={{ backgroundColor: '#EAF2FA', borderRadius: "5px", height: '100px', width: '100%' }}>
            <div className='col-2  d-flex justify-content-center align-items-center '>
              <div className='' >
                <div className=''>
                  <FormControlLabel
                    control={<Checkbox checked={checkSelect === 1 ? true : false} onChange={() => { handleCheckSelect(1) }} />}
                    label="Single Report"
                  />
                </div>
                <div className=''>
                  <FormControlLabel
                    control={<Checkbox checked={checkSelect === 2 ? true : false} onChange={() => { handleCheckSelect(2) }} />}
                    label="Multi Report"
                  />
                </div>
              </div>
            </div>
            <div className='col-3  d-flex justify-content-center align-items-center '>
              <Autocomplete
                options={datas}
                getOptionLabel={(option) => option}
                value={addPatientName}
                onChange={(e, v) => {
                  setAddPatientName(v);
                  if (v !== "") {
                    setSelectedRows([])
                    setMultiReportData([])
                    setReportData([])
                    let newPatientData = addNewPatient?.filter((m: any) =>
                      m.basicDetails[0].name[0].given + " " + m.basicDetails[0].name[0].family === v
                    )?.map((k) => { return k.id })
                    newInputData.patientName = newPatientData[0]
                    setNewInputData({ ...newInputData, date: '' })
                    setNameError({ ...nameError, patientName: false })
                    setOutputSave(false);
                  }
                }}
                sx={{ width: "90%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="patient name"
                    margin="none"
                    fullWidth
                    error={!!nameError.patientName}
                    required
                  />
                )}
              />
            </div>
            <div className='col-2  d-flex justify-content-center align-items-center  ' >
              <Autocomplete
                options={dates}
                getOptionLabel={(option) => option}
                value={outputSave === true ? value : ''}
                onChange={(e, v) => {
                  setValue(v);
                  setSingleDate([moment(v).format("YYYYMMDD")])
                  // handleRowSelected(v)
                  // setSelectedRows([...selectedRows, moment(v).format("YYYYMMDD")])
                  setNameError({ ...nameError, date: false });
                  setOutputSave(true);
                }}
                freeSolo
                sx={{ width: "82%" }}
                disabled={checkSelect === 2 ? true : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select date"
                    error={!!nameError.date}
                    required
                  />
                )}
              />
            </div>
            <div className='col-2  d-flex justify-content-center align-items-center  '>
              <Autocomplete
                options={option}
                getOptionLabel={(option) => option}
                value={selectShift}
                onChange={(e, v) => {
                  setSelectShift(v)
                }}
                sx={{ width: "82%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Shift"
                    placeholder="Shift"
                    margin="none"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className='col-2  d-flex justify-content-center align-items-center  ' >
              <FormControlLabel
                control={<Checkbox checked={disabled} onChange={handleCheck} />}
                label="Proximity"
              />
            </div>
            <div className='col-1  d-flex justify-content-center align-items-center     '>
              <Button label='submit' style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handlesumbit}>Submit</Button>
            </div>
          </div>
          {
            checkSelect === 2 &&
            <div style={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }} className=''>
              {(addPatientName && dates) && (
                <Table hover style={{ width: '100%', textAlign: 'center' }} >
                  <thead >
                    <tr className='' style={{ top: 0, position: 'sticky' }}>
                      <th style={{ color: primarytext, backgroundColor: grey }} >Date</th>
                      <th style={{ color: primarytext, backgroundColor: grey }} >Patient Name</th>
                      <th style={{ color: primarytext, backgroundColor: grey }} >Shift</th>
                      <th style={{ color: primarytext, backgroundColor: grey }} >Proximity</th>
                      <th style={{ color: primarytext, backgroundColor: grey }} >Select</th>
                    </tr>
                  </thead>
                  <tbody >
                    {dates && dates.map((item: any, inx: number) => {
                      return (
                        <tr key={inx} className='' style={{}}>
                          <td>{item && moment(item).format("DD-MM-YYYY")}</td>
                          <td>{addPatientName}</td>
                          <td>{selectShift ? selectShift : 'Full Shift'}</td>
                          <td>{disabled ? disabled && "Proximity" : ''}</td>
                          <td>
                            {/* <Checkbox onChange={()=>{handleRowSelected(item)}} /> */}
                            <input type='checkbox' checked={selectedRows.includes(moment(item).format("YYYYMMDD"))}  onChange={() => { handleRowSelected(item) }} />
                          </td>
                        </tr>)
                    })}
                  </tbody>
                </Table >)}
            </div>
          }
          {/* Report View Header End */}
          {/* Multi Report Summary&Details View Start */}
          <div className='mt-2'>
            {
              checkSelect === 2 &&
              <MultipleReportView
                multiReportData={multiReportData}
                View={accordionView}
                selectShift={selectShift}
                selectShiftView={selectShiftView}
                checkBox={checkBox} />
            }
          </div>
          {/* Multi Report Summary&Details View End */}
        </div>
      </div>
      <div hidden={checkSelect === 2 ? true : false} >
        {reportData !== null && reportData !== undefined && reportData?.map((data: any, inx: number) => {
          return (
            <>
              <div className='container-fluid overflow-auto' id='1to1AllReport' ref={pdfRef} >
                {/* Report Time Am View Start */}
                <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                  {data && <>
                    <div className='d-flex justify-content-between'>
                      <div className='col-8'>
                        <div className='d-flex flex-row gap-3'>
                          <div><strong>Date: </strong>{data.date && moment(data.date).format("DD-MM-YYYY")} </div>
                          {selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>}
                        </div>
                        <strong>Patient Name: </strong>{data.patientId}
                      </div>
                      <div className='d-flex justify-content-between col-4'>
                        <div>
                          <strong>Room No: </strong>
                          {data.assignedBed && data.assignedBed.split('-')[0]}
                        </div>
                        <div>
                          <strong className='pe-3'>AssignedBed:</strong>
                          {data.assignedBed && data.assignedBed.split('-')[1]}
                        </div>
                      </div>
                    </div>
                    <div className='mt-2'>
                      <table style={{ width: '100%', fontSize: '14px' }}>
                        <thead >
                          <tr style={{ height: '25px', border: '1px solid #C9C9C9', textAlign: 'center', fontWeight: '600' }}>
                            <td style={{ width: '10%', border: '1px solid #C9C9C9' }} >Init</td>
                            <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Print Nurses Name </td>
                            <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Nurse Signature </td>
                            <td style={{ width: '10%', border: '1px solid #C9C9C9' }}>Time</td>
                            <td style={{ width: '15%', border: '1px solid #C9C9C9' }}>SUICIDE RISK</td>
                          </tr>
                        </thead>
                        <tbody style={{ border: '1px solid #C9C9C9' }}>
                          <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px' }}>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftAIncharge}</th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftATime}</th>
                            <th style={{ width: '15%', paddingLeft: '20px' }}>
                              <div className="form-check " style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled checked={data?.suicideRisk === 'low'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                <label className="" style={{ fontSize: '16px', fontWeight: 'normal', color: 'black' }} >
                                  Low
                                </label>
                              </div>
                            </th>
                          </tr>
                          <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px' }}>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftBIncharge}</th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftBTime}</th>
                            <th style={{ width: '15%', paddingLeft: '20px' }}>
                              <div className="form-check " style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled checked={data?.suicideRisk === 'moderate'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                <label className="" style={{ fontSize: '16px', fontWeight: 'normal' }} >
                                  Moderate
                                </label>
                              </div>
                            </th>
                          </tr>
                          <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px' }}>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftCIncharge}</th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftCTime}</th>
                            <th style={{ width: '15%', paddingLeft: '20px' }}>
                              <div className="form-check " style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled checked={data?.suicideRisk === 'high'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                <label className="" style={{ fontSize: '16px', fontWeight: 'normal' }} >
                                  High
                                </label>
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {!selectShiftView ? <>
                      <div className='mt-3' style={{ fontSize: '14px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                          <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                          <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                          <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                          <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                          <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                          <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                          <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                          <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                          <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                          <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                        </div>
                      </div>
                      <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                        <div style={{ width: '50%', fontSize: '14px' }}>
                          {data && data.configResponses?.map((t: any, ind: number) => {
                            return ind >= 0 && ind <= 23 && (<>
                              <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                                <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                              </div>
                            </>);
                          })}
                        </div>
                        <div style={{ width: '50%', fontSize: '14px' }}>
                          {data && data.configResponses?.map((t: any, ind: number) => {
                            return ind >= 24 && ind <= 47 && (<>
                              <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                                <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                              </div>
                            </>);
                          })}
                        </div>
                      </div>
                    </>
                      :
                      <>
                        {/*  Report Shift based View Start */}
                        <div>
                          <div className='mt-3' style={{ fontSize: '14px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                              <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                              <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                              <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                              <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                            </div>
                          </div>
                          <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                            <div style={{ width: '50%', fontSize: '14px' }}>
                              {data && data.configResponses?.map((t: any, ind: number) => {
                                return ind >= 0 && ind <= 15 && (<>
                                  <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                                    <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                  </div>
                                </>);
                              })}
                            </div>
                            <div style={{ width: '50%', fontSize: '14px' }}>
                              {data && data.configResponses?.map((t: any, ind: number) => {
                                return ind >= 16 && ind <= 31 && (<>
                                  <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                                    <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                  </div>
                                </>);
                              })}
                            </div>
                          </div>
                        </div>
                        {/* Report Shift based View End */}
                      </>}
                    <div style={{ width: '100%', backgroundColor: '#EAF2FA', display: 'flex', flexDirection: 'row', gap: '5px', paddingTop: '5px', paddingBottom: '5px' }}>
                      <div style={{ width: '45%', fontSize: '13px', paddingLeft: '10px', paddingRight: '5px' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black', fontWeight: '600' }}>
                          <div style={{ width: '25%' }}>Location</div>
                          <div style={{ width: '25%' }}>Code</div>
                          <div style={{ width: '25%' }}>Location</div>
                          <div style={{ width: '25%' }}>Code</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>PT Room</div>
                          <div style={{ width: '25%' }}>R</div>
                          <div style={{ width: '25%' }}>Conf Rm</div>
                          <div style={{ width: '25%' }}>C</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>Day Room</div>
                          <div style={{ width: '25%' }}>DR</div>
                          <div style={{ width: '25%' }}>Mid Line</div>
                          <div style={{ width: '25%' }}>L</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>Dining RM</div>
                          <div style={{ width: '25%' }}>D</div>
                          <div style={{ width: '25%' }}>Quiet RM</div>
                          <div style={{ width: '25%' }}>QR</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>Nurses ST.</div>
                          <div style={{ width: '25%' }}>N</div>
                          <div style={{ width: '25%' }}>Shower</div>
                          <div style={{ width: '25%' }}>S</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>Telephone</div>
                          <div style={{ width: '25%' }}>T</div>
                          <div style={{ width: '25%' }}>Seclusion</div>
                          <div style={{ width: '25%' }}>SC</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>BathRoom</div>
                          <div style={{ width: '25%' }}>B</div>
                          <div style={{ width: '25%' }}>Hall</div>
                          <div style={{ width: '25%' }}>H</div>
                        </div>
                      </div>
                      <div style={{ width: '55%', fontSize: '13px', paddingLeft: '0px', paddingRight: '5px' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black' }}>
                          <div style={{ width: '100%', fontWeight: '600' }}>Behavior Code: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; See 1-10</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>1. Sleeping (3 respirations observed)</div>
                          <div style={{ width: '44%' }}>7. Oppositional</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>2. Calm, interacting appropriately</div>
                          <div style={{ width: '44%' }}>8. Bizarre behavior</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>3. Withdrawn/Quiet</div>
                          <div style={{ width: '44%' }}>9. Shouting/loud/agitated</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>4. Crying, tearful</div>
                          <div style={{ width: '44%' }}>10. Out of control, aggressive</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>5. Anxious, restless</div>
                          <div style={{ width: '44%' }}></div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>6. Elated, hyperactive, manic</div>
                          <div style={{ width: '44%' }}></div>
                        </div>
                      </div>
                    </div>
                    <article style={{ width: '100%' }} className=''>
                      <div style={{ width: '100%', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: '600' }}>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                        </div>
                      </div>
                      <div style={{ width: '100%', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                        </div>
                      </div>
                      <div style={{ width: '100%', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                        </div>
                      </div>
                      <div style={{ width: '100%', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                        </div>
                      </div>
                      <div style={{ width: '100%', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'auto' }}>
                          <div style={{ width: '100%', border: '1px solid #C9C9C9', paddingBottom: '10px' }}>
                            <div style={{ padding: '1px 7px', fontWeight: '600' }}>PRECAUTIONS MEANING: </div>
                            <div style={{ display: 'flex', flex: 'row', width: '100%' }}>
                              <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEXUALLY ACTING OUT (SAO)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    SAO: SEXUALLY ACTING OUT
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ACTIVE SUICIDE PREC (ASP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    ASP: ACTIVE SUICIDE PREC.
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "FALL PREC (FP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    FP: FALL PREC.
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "GEN SAFETY PREC (GSP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    GSP: GEN SAFETY PREC.
                                  </label>
                                </div>
                              </div>
                              <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-ETOH")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    DETOX-ETOH
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-DRUG")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    DETOX-DRUG
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ASSAULT PREC (AP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    AP: ASSAULT PREC.
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ELOPEMENT PREC (EP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    EP: ELOPEMENT PREC
                                  </label>
                                </div>
                              </div>
                              <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ONE TO ONE (1:1)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    1:1: ONE TO ONE
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "OBSERVATION")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    OBSERVATION
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEIZURE PREC (SP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    SZ: SEIZURE PREC.
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                        </div>
                      </div>
                    </article>
                  </>}
                </section>
                {/*  Report Time AM View End  */}
                {/* Report Time PM View Start  */}
                {!selectShiftView && <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}2`}>
                  {data && <>
                    <div className='d-flex justify-content-between'>
                      <div className='col-8'>
                        <div className='d-flex flex-row gap-3'>
                          <div><strong>Date: </strong>{data.date && moment(data.date).format("DD-MM-YYYY")}</div>
                          {selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>}
                        </div>
                        <strong>Patient Name: </strong>{data.patientId}
                      </div>
                      <div className='d-flex justify-content-between col-4'>
                        <div>
                          <strong>Room No: </strong>
                          {data.assignedBed && data.assignedBed.split('-')[0]}
                        </div>
                        <div>
                          <strong className='pe-3'>AssignedBed:</strong>
                          {data.assignedBed && data.assignedBed.split('-')[1]}
                        </div>
                      </div>
                    </div>
                    <div className='mt-2'>
                      <table style={{ width: '100%', fontSize: '14px' }}>
                        <thead >
                          <tr style={{ height: '25px', border: '1px solid #C9C9C9', textAlign: 'center', fontWeight: '600' }}>
                            <td style={{ width: '10%', border: '1px solid #C9C9C9' }} >Init</td>
                            <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Print Nurses Name </td>
                            <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Nurse Signature </td>
                            <td style={{ width: '10%', border: '1px solid #C9C9C9' }}>Time</td>
                            <td style={{ width: '15%', border: '1px solid #C9C9C9' }}>SUICIDE RISK</td>
                          </tr>
                        </thead>
                        <tbody style={{ border: '1px solid #C9C9C9' }}>
                          <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px' }}>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftAIncharge}</th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftATime}</th>
                            <th style={{ width: '15%', paddingLeft: '20px' }}>
                              <div className="form-check " style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled checked={data?.suicideRisk === 'low'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                <label className="" style={{ fontSize: '16px', fontWeight: 'normal', color: 'black' }} >
                                  Low
                                </label>
                              </div>
                            </th>
                          </tr>
                          <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px' }}>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftBIncharge}</th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftBTime}</th>
                            <th style={{ width: '15%', paddingLeft: '20px' }}>
                              <div className="form-check " style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled checked={data?.suicideRisk === 'moderate'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                <label className="" style={{ fontSize: '16px', fontWeight: 'normal' }} >
                                  Moderate
                                </label>
                              </div>
                            </th>
                          </tr>
                          <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px' }}>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftCIncharge}</th>
                            <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                            <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftCTime}</th>
                            <th style={{ width: '15%', paddingLeft: '20px' }}>
                              <div className="form-check " style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled checked={data?.suicideRisk === 'high'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                <label className="" style={{ fontSize: '16px', fontWeight: 'normal' }} >
                                  High
                                </label>
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='mt-3' style={{ fontSize: '14px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                        <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                        <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                      </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                      <div style={{ width: '50%', fontSize: '14px' }}>
                        {data && data.configResponses?.map((t: any, ind: number) => {
                          return ind >= 48 && ind <= 71 && (<>
                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                              <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                              <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                            </div>
                          </>);
                        })}
                      </div>
                      <div style={{ width: '50%', fontSize: '14px' }}>
                        {data && data.configResponses?.map((t: any, ind: number) => {
                          return ind >= 72 && ind <= 95 && (<>
                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                              <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                              <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                            </div>
                          </>);
                        })}
                      </div>
                    </div>
                    <div style={{ width: '100%', backgroundColor: '#EAF2FA', display: 'flex', flexDirection: 'row', gap: '5px', paddingTop: '5px', paddingBottom: '5px' }}>
                      <div style={{ width: '45%', fontSize: '13px', paddingLeft: '10px', paddingRight: '5px' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black', fontWeight: '600' }}>
                          <div style={{ width: '25%' }}>Location</div>
                          <div style={{ width: '25%' }}>Code</div>
                          <div style={{ width: '25%' }}>Location</div>
                          <div style={{ width: '25%' }}>Code</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>PT Room</div>
                          <div style={{ width: '25%' }}>R</div>
                          <div style={{ width: '25%' }}>Conf Rm</div>
                          <div style={{ width: '25%' }}>C</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>Day Room</div>
                          <div style={{ width: '25%' }}>DR</div>
                          <div style={{ width: '25%' }}>Mid Line</div>
                          <div style={{ width: '25%' }}>L</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>Dining RM</div>
                          <div style={{ width: '25%' }}>D</div>
                          <div style={{ width: '25%' }}>Quiet RM</div>
                          <div style={{ width: '25%' }}>QR</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>Nurses ST.</div>
                          <div style={{ width: '25%' }}>N</div>
                          <div style={{ width: '25%' }}>Shower</div>
                          <div style={{ width: '25%' }}>S</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>Telephone</div>
                          <div style={{ width: '25%' }}>T</div>
                          <div style={{ width: '25%' }}>Seclusion</div>
                          <div style={{ width: '25%' }}>SC</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '25%' }}>BathRoom</div>
                          <div style={{ width: '25%' }}>B</div>
                          <div style={{ width: '25%' }}>Hall</div>
                          <div style={{ width: '25%' }}>H</div>
                        </div>
                      </div>
                      <div style={{ width: '55%', fontSize: '13px', paddingLeft: '0px', paddingRight: '5px' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black' }}>
                          <div style={{ width: '100%', fontWeight: '600' }}>Behavior Code: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; See 1-10</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>1. Sleeping (3 respirations observed)</div>
                          <div style={{ width: '44%' }}>7. Oppositional</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>2. Calm, interacting appropriately</div>
                          <div style={{ width: '44%' }}>8. Bizarre behavior</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>3. Withdrawn/Quiet</div>
                          <div style={{ width: '44%' }}>9. Shouting/loud/agitated</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>4. Crying, tearful</div>
                          <div style={{ width: '44%' }}>10. Out of control, aggressive</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>5. Anxious, restless</div>
                          <div style={{ width: '44%' }}></div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '56%' }}>6. Elated, hyperactive, manic</div>
                          <div style={{ width: '44%' }}></div>
                        </div>
                      </div>
                    </div>
                    <article style={{ width: '100%' }} className=''>
                      <div style={{ width: '100%', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: '600' }}>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                        </div>
                      </div>
                      <div style={{ width: '100%', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                        </div>
                      </div>
                      <div style={{ width: '100%', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                        </div>
                      </div>
                      <div style={{ width: '100%', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                        </div>
                      </div>
                      <div style={{ width: '100%', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'auto' }}>
                          <div style={{ width: '100%', border: '1px solid #C9C9C9', paddingBottom: '10px' }}>
                            <div style={{ padding: '1px 7px', fontWeight: '600' }}>PRECAUTIONS MEANING: </div>
                            <div style={{ display: 'flex', flex: 'row', width: '100%' }}>
                              <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEXUALLY ACTING OUT (SAO)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    SAO: SEXUALLY ACTING OUT
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ACTIVE SUICIDE PREC (ASP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    ASP: ACTIVE SUICIDE PREC.
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "FALL PREC (FP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    FP: FALL PREC.
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "GEN SAFETY PREC (GSP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    GSP: GEN SAFETY PREC.
                                  </label>
                                </div>
                              </div>
                              <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-ETOH")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    DETOX-ETOH
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-DRUG")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    DETOX-DRUG
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ASSAULT PREC (AP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    AP: ASSAULT PREC.
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ELOPEMENT PREC (EP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    EP: ELOPEMENT PREC
                                  </label>
                                </div>
                              </div>
                              <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ONE TO ONE (1:1)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    1:1: ONE TO ONE
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "OBSERVATION")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    OBSERVATION
                                  </label>
                                </div>
                                <div className="form-check " style={{ height: '11px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEIZURE PREC (SP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                  <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                    SZ: SEIZURE PREC.
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                        </div>
                      </div>
                    </article>
                  </>
                  }
                </section>}
                {/* Single Report Time PM View End  */}
                {/* Notes Table Start */}
                {/* <section className={`px-5 ${filterdata.length > 0 ? 'py-3': 'py-0' }`}>
             {
               (tableData && filterdata.length>0) && 
               <>
                 <div className='mt-2' style={{width: '100%', fontSize: '13px'}}>
                   <div style={{ display: 'flex', flexDirection: 'row' }}>
                     <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff</div>
                     <div style={{ width: '10%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time</div>
                     <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Notes</div>
                     <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff</div>
                     <div style={{ width: '10%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                     <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Notes</div>
                   </div>
                 </div>
                 <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                   <div style={{ width: '50%', fontSize: '14px' }}>
                     { filterdata && filterdata?.map((item: any, inx: number) => {
                       const staffTime =  moment(item.time ,"YYYYMMDDHHmmss").format("HH:mm")
                       return inx >=0 && inx <= ((filterdata.length/2)-1) && (
                         <>
                           <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={inx}>
                             <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.staff}</div>
                             <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{staffTime && staffTime}</div>
                             <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.note}</div>
                           </div>
                         </>  
                        )
                       })
                     }
                   </div>                    
                   <div style={{ width: '50%', fontSize: '14px' }}>
                     { filterdata && filterdata?.map((item: any, inx: number) => {
                       const staffTime =  moment(item.time ,"YYYYMMDDHHmmss").format("HH:mm")
                       return inx >= (filterdata.length/2) && inx <= (filterdata.length-1) && (
                         <>
                           <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={inx}>
                             <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.staff}</div>
                             <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{staffTime && staffTime}</div>
                             <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.note}</div>
                           </div>
                         </>  
                        )
                       })
                     }
                   </div>
                 </div>
               </> 
             }
           </section > */}
                {/* Notes Table End */}
              </div>
            </>
          )
        })}
      </div>
    </div>
  )
}
export default OneToOneReportView