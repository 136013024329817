import { Navigate } from 'react-router-dom'
import Login from '../pages/login'
import Q15StaffConfiguration from '../pages/staffConfiguration'
import SecretKey from '../pages/secretkey'
import Organization from '../pages/organizationDetails'
import Staff from '../pages/Staff/intex'
import Patient from '../pages/Patient/intex'
import AllPatient from '../pages/AllPatient'
import Beacon from '../pages/beaconDevices'
import Q15Report from '../pages/q15Report'
import Q15ReportView from '../pages/q15Report/reportView'
import BedAssign from '../pages/bedAssign'
import PatientAssign from '../pages/patientAssign'
import BedCreation from '../pages/bedAssign/bedCreation'
import ForgotPassword from '../pages/forgotPassword'
import VerifyOtp from '../pages/verifyOtp'
import ChangePassword from '../pages/changePassword'
import ResetSecretKey from '../pages/resetSecretKey'
import ParticularOrgUpdate from '../pages/organizationDetails/particularOrgUpdate'
import RecreatePassword from '../pages/recreatePassword'
import BedAddAssign from '../pages/bedAssign/BedAddAssign'
import Q15SlotAssign from '../pages/Q15SlotAssign/q15Slot'
import AdmitPatient from '../pages/Patient/AdmitPatient'
import PatientView from '../pages/LargeScreen/PatientDisplay'
import Unblock from '../pages/secretkey/Unblock'
import StaffConfigure from '../pages/staffConfiguration/staffConfigure'
import OrgUpdate from '../pages/organizationDetails/orgUpdate'
import OneToOne from '../pages/OneToOne'
import StaffDisplay from '../pages/LargeScreen/StaffDisplay'
import OneHourStaffConfig from '../pages/staffConfiguration/oneHourStaffConfig'
import OneToOneReportView from '../pages/OneToOneReport/OneToOneReport'
import Restraint from '../pages/q15Report/restraint'
import StaffSos from '../pages/staffConfiguration/staffSos'
import ErrorPage from '../components/errorPopup/errorPage'
import PageNotFound from '../components/errorPopup/404Page'

const SuperAdminRoutes = [
  { path: '/organization-details', component: <Organization /> },
  { path:'/org-update/:id',component:<OrgUpdate />},
  { path: '/beacon-table/:id', component: <Beacon/>},
];

const StaffRoutes = [
  { path:'/q15-slot-assign',component:<Q15SlotAssign/>},
  { path: '/q15-report', component: <Q15Report/>},
  { path: '/q15-report-view', component: <Q15ReportView/>},
  { path: '/sos-staff', component: <StaffSos/> },
]
const SystemAdminRoutes = [
  { path:'/organization-update/:id',component:<ParticularOrgUpdate />},
  { path: '/staff-table', component: <Staff /> },
  { path: '/beacon-table', component: <Beacon/>},
  { path:'/add-bed-table',component:<BedAddAssign/>},
]
const AdminRoutes = [
  { path: '/q15-staff-configuration', component: <Q15StaffConfiguration /> },
  { path: '/one-hour-staff-configuration', component: <OneHourStaffConfig /> },
  { path: '/One-To-One', component: <OneToOne /> },
  { path: '/staff-configuration', component: <StaffConfigure /> },
  // { path: '/staff-configuration', component: <StaffSos /> },
  { path: '/patient-table', component: <Patient />},
  { path: '/all-patient-table', component: <AllPatient />},
  { path: '/q15-report', component: <Q15Report/>},
  { path: '/restraint-seculation', component: <Restraint />},
  { path: '/q15-report-view', component: <Q15ReportView/>},
  { path: '/one-to-one-report', component: <OneToOneReportView/>},
  { path:'/bed-assign', component:<BedCreation/>},
  { path:'/bed-table',component:<BedAssign/>},
  { path:'/add-bed-table',component:<BedAddAssign />},
  { path:'/q15-slot-assign',component:<Q15SlotAssign/>},
  { path:'/patient-assign',component:<PatientAssign/>},
  { path:'/patient-admit',component:<AdmitPatient/>},
  { path:'/patient-view',component:<PatientView/>},
  { path:'/staff-view',component:<StaffDisplay/>},
  { path:'/*',component:<ErrorPage/>},
];

const publicRoutes = [
  { path: '/', exact: true, component: <Navigate to="/login" /> },
  { path: '/login', component: <Login />},
  { path: '/secret-key', component: <SecretKey />},
  { path:'/forgot-password',component:<ForgotPassword/>},
  { path:'/verify-otp',component:<VerifyOtp/>},
  { path:'/change-password',component:<ChangePassword/>},
  { path:'/resetSecretKey',component:<ResetSecretKey/>},
  { path: '/account-unblock', component: <Unblock />},
  { path: '/recreatePassword', component:<RecreatePassword/>},
  { path:'/*',component:<PageNotFound/>},
]


export { AdminRoutes, SuperAdminRoutes, publicRoutes, SystemAdminRoutes, StaffRoutes }