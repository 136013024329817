import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import {
  getAllBedAssign
} from "../../slices/bedAssign/thunk";
import { getAllBed } from "../../slices/patientAssign/thunk";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardFooter,
  Badge,
  Button
} from "reactstrap";
import "./bedassign.css";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Table,
} from "reactstrap";
import { Pagination } from "react-bootstrap";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { baseURL, successCode } from "../../configuration/url";
import roomimg from "../../assets/images/room.svg";
import bedimg from "../../assets/images/bed.svg";
import LogoImg from "../../assets/images/profile.svg";
import { toast } from "react-toastify";
import "../bedAssign/bedassign.css"
import { Search } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { getPatientDropdown } from "../../slices/thunk";
interface FormData {
  id: string;
  bedId: string;
  pid: string;
  orgId: string;
}
interface Bed {
  roomNoStart: string;
  roomNoEnd: string;
  bedNo: string;
  oddOrEven: string,
  organization: string,
}
type BedAssign = {
  roomNo: string;
  bedNo: string;
  pid: string;
  patientName: string;
};
type BedAssigndata = {
  roomNo: string;
  bedNo: string;
  pid: string;
  bedId: string
  patientName: string;
};
const BedAssign: React.FC = () => {
  const dispatch = useDispatch<any>();
  const [bedId, setBedId] = useState<string | null>(null);
  const [editModal, setEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [patientAndBedAssign, setPatientAndBedAssign] = useState<any[]>([]);
  const [value, setValue] = React.useState('1');
  let [increment, setIncrement] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event: any, newValue: any) => {
    setSearchTerm("");
    setValue(newValue);
    getAllBedAssign(dispatch, organization);
    getAllBed(dispatch, organization);
  };

  const { loading } = useSelector(
    (state: any) => state.BedAssign
  );

  const { bedAssignData = [], }: { bedAssignData: BedAssigndata[] } = useSelector(
    (state: any) => state.BedAssign
  );
  const { patientAssignData = [] }: { patientAssignData: BedAssign[] } = useSelector(
    (state: any) => state.PatientAssign
  );
  const { organization } = useSelector((state: any) => state.Login);
  const { patientData, patientDropdown } = useSelector((state: any) => state.Patient);
  const selectedPatientId = patientData?.id;
  const [bedAssignedData, setBedAssignedData] = useState<FormData>({
    id: "",
    bedId: bedAssignData[0]?.bedId,
    pid: selectedPatientId || "",
    orgId: organization,
  });

  let [newAssignedBy, setAssignedBy] = useState<string | null>(null);
  const handlePatientChange = (selectedPatientId: string) => {
    setBedAssignedData((prevData) => ({ ...prevData, pid: selectedPatientId }));
  };

  useEffect(() => {
    getAllBedAssign(dispatch, organization);
    getPatientDropdown(dispatch, organization);
    fetchPatientsandBedAssign();
    setAssignedBy(window.localStorage.getItem("LoginData"));
  }, [organization]);

  const [formData, setFormData] = useState<Bed>({
    roomNoStart: "",
    roomNoEnd: "",
    bedNo: "",
    oddOrEven: "",
    organization: organization,
  });
  const handleSave = async () => {
    const requestBody = {
      bedId: bedId,
      pid: bedAssignedData.pid,
      assignedBy: newAssignedBy,
      admitDate: new Date().toISOString().slice(0, 10).replace(/-/g, "")
    };
    try {
      const response = await axios.post(
        `${baseURL}/Q15Bed/assign`,
        requestBody
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) {
        alert(response.data.message.description);
        setEditModal(false);
        getAllBedAssign(dispatch, organization);
        getAllBed(dispatch, organization)
      } else {
        console.error("Error:", response.data.message);
        alert(`Error: ${response.data.message.description}`);
      }
    } catch (error) {
      console.error("API Request Error:", error);
      alert("An error occurred. Please check console for details.");
    } finally {
      setEditModal(false);
    }
  };
  const [bed, setBed] = useState('')

  const handleClick = (selectedBed: any) => {
    if (selectedBed) {
      const bedNo = selectedBed.roomNo + "-" + selectedBed.bedNo;
      setBed(bedNo)
      const bedAssignId = selectedBed.id || " ";
      setBedId(bedAssignId);
      setBedAssignedData({
        id: selectedBed.id,
        bedId: selectedBed.bedId,
        pid: selectedBed.pid,
        orgId: selectedBed.orgId,
      });
      setEditModal(true);
    } else {
      console.error("Invalid Data:", selectedBed);
    }
  };

  const fetchPatientsandBedAssign = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByOrg/${organization}`
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        setPatientAndBedAssign(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  // const { patientAssignData = [] } = useSelector(
  //   (state: any) => state.PatientAssign
  // );

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / perPage);
  useEffect(() => {
    getAllBed(dispatch, organization);
  }, [dispatch, organization]);


  const handleSaveBed = async () => {
    if (!formData.bedNo) {
      alert("Please fill All The Fields");
      return;
    }
    const requestBody = {
      roomNoStart: formData.roomNoStart,
      roomNoEnd: formData.roomNoEnd,
      bedNo: formData.bedNo,
      oddOrEven: formData.oddOrEven,
      organization: formData.organization
    };
    try {
      const response = await axios.post(
        `${baseURL}/Q15Bed/create`,
        requestBody
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) {
        toast.success(response.data.message.description);
        setEditModal(false);
        getAllBedAssign(dispatch, organization);
        getAllBed(dispatch, organization);
      } else {
        toast.error(`Error:${response.data.message.description}`);
      }
    } catch (error) {
      alert("Room No and Bed No Already Exists");

    }
    setEditModal(false);
  };
  const itemsPerPage = 8;
  const [bedCurrentPage, setBedCurrentPage] = useState(1);
  const indexOfLastItem = bedCurrentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBedAssignData =
    bedAssignData && bedAssignData?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setBedCurrentPage(pageNumber);
  const renderPageNumbers = () => {
    const totalPages = Math.ceil(bedAssignData.length / itemsPerPage);

    const pageNumbersToShow = Math.min(5, totalPages);

    let startPage: number;
    let endPage: number;

    if (totalPages <= pageNumbersToShow) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const middlePage = Math.ceil(pageNumbersToShow / 2);

      if (bedCurrentPage <= middlePage) {
        startPage = 1;
        endPage = pageNumbersToShow;
      } else if (bedCurrentPage + middlePage >= totalPages) {
        startPage = totalPages - pageNumbersToShow + 1;
        endPage = totalPages;
      } else {
        startPage = bedCurrentPage - middlePage + 1;
        endPage = bedCurrentPage + middlePage - 1;
      }
    }
    return Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
      <Pagination.Item
        key={startPage + index}
        active={startPage + index === bedCurrentPage}
        onClick={() => paginate(startPage + index)}
      >
        {startPage + index}
      </Pagination.Item>
    ));
  }
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredBedList = patientAndBedAssign.filter((bedassign: any) => (
    // Assuming roomNo and bedNo are strings, you can customize the filtering logic based on your needs
    bedassign.roomNo.toLowerCase().includes(searchTerm.toLowerCase()) 
  ));
  const bedsByNumber = filteredBedList.reduce<{ [key: string]: any[] }>((acc, bedassign) => {
    const { roomNo, bedNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {});

  const filteredBedList1 = patientAssignData.filter((bedassign: any) => {
    // Assuming roomNo and bedNo are strings, you can customize the filtering logic based on your needs
    return bedassign.roomNo.toLowerCase().includes(searchTerm.toLowerCase()) 
  });

  const occupaidbedsByNumber = filteredBedList1.reduce<{ [key: string]: BedAssign[] }>((acc, bedassign) => {
    const { roomNo, bedNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {} as { [key: string]: BedAssign[] });

  const filteredBedList2 = bedAssignData.filter((bedassign: any) => {
    // Assuming roomNo and bedNo are strings, you can customize the filtering logic based on your needs
    return bedassign.roomNo.toLowerCase().includes(searchTerm.toLowerCase()) 
  });
  const abailablebedsByNumber = filteredBedList2.reduce<{ [key: string]: BedAssign[] }>((acc, bedassign) => {
    const { roomNo, bedNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {} as { [key: string]: BedAssign[] });
  return (
    
    <div className="container m15 p3 " style={{ width: "90%", height: 'auto' }}>
      {loading && <Loader />}
      <div className="d-flex mt-2 justify-content-between">
        <h5 style={{top: 0}}>Bed Details</h5>
        <div className="col-md-5 d-flex justify-content-end gap-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon
                        icon={faCircle}
                        style={{ color: '#f8b19570', marginRight: '5px' }}
                    />
                    <span>Bed no: 01</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon
                    icon={faCircle}
                    style={{ color: '#c06c8470', marginRight: '5px'  }}
                />
                <span>Bed no: 02</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon
                    icon={faCircle}
                    style={{ color: '#355c7d52', marginRight: '5px'  }}
                />
                <span>Bed no: 03</span>
                </div>
                </div>
        <div className="">
        <TextField
            label="Search Room Number"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              style: { height: '45px' },
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { fontSize: '14px' },
            }}
            fullWidth
          />
          </div>
      </div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All Beds" value="1" />
              <Tab label="Occupied" value="2" />
              <Tab label="Available" value="3" />
            </TabList>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}> 
            <TabPanel  
              sx={{ padding: '0px' }} 
              style={{ 
                flex: 1, 
                maxHeight: 'calc(100vh - 180px)', // Adjust based on your layout
                overflowY: 'auto', 
                padding: '0',
                transition: 'max-height 0.3s ease' // Smooth transition for resizing
              }} 
              value="1"
            >
            <div>
              <div className="container m15 p3" >
                <div className="grid-templetcontainer" style={{ justifyItems: "center" }} >
                  {Object.entries(bedsByNumber).map(([bedNo, assignments]) => (
                    <Row key={bedNo}
                    style={{ display: "flex", width: "100%" }}
                    // style={{ display: "flex", flexWrap: "wrap", justifyContent: bedNo === '1' ? 'space-evenly' : 'flex-start', marginBottom: "40px" }}
                    >
                      {assignments.map((bedassign, index) => (
                        bedassign.patientName !== null  ? <>
                          <Col key={bedassign.id} style={{ flex: 0, padding: 0, width: "33.3%"  }}>
                            <div className="bed-assignment-box">
                              <Card
                                className="mb-3"
                                color="danger"
                                outline
                                style={{
                                  width: "92px",
                                  height: "70px",
                                  margin: "5px",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <CardBody
                                  key={index}
                                  style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                                >
                                  <CardTitle tag="h6">
                                    <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                                  </CardTitle>
                                  <CardSubtitle tag="h6" className=" text-muted">
                                    <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                                  </CardSubtitle>
                                </CardBody>
                                <CardFooter style={{
                                  padding: '7px', position: 'relative', display: 'flex', top: '-13px', height: '33px', fontSize: '10px', fontWeight: 'bold', lineHeight: 'normal', justifyContent: "space-evenly",
                                  background: bedassign.bedNo === '01' ? '#f8b19570' : (bedassign.bedNo === '02' ? '#c06c8470' : (bedassign.bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                                }}>
                                  <img src={LogoImg} style={{ width: '20px', height: '20px' }}></img><span className="truncate-hover" style={{ paddingLeft: '5px', lineHeight: 2 }}>{bedassign.patientName}</span>
                                </CardFooter>
                              </Card>
                            </div>
                          </Col>
                        </> : <>
                          <Col key={index} style={{ flex: 0, padding: 0, width: "33.3%"  }}>
                            <div className="bed-assignment-box">
                              <Card
                                className="mb-3"
                                color="primary"
                                outline
                                style={{
                                  width: "92px",
                                  height: "70px",
                                  margin: "5px",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <CardBody
                                  key={index}
                                  onClick={() => handleClick(bedassign)}
                                  style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                                >
                                  <CardTitle tag="h6">
                                    <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                                  </CardTitle>
                                  <CardSubtitle tag="h6" className="text-muted ">
                                    <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                                  </CardSubtitle>
                                </CardBody>

                                <CardFooter style={{
                                  padding: '2px 0.6rem 5px 13px ', position: 'relative', top: '-13px', height: '33px',
                                  background: bedassign.bedNo === '01' ? '#f8b19570' : (bedassign.bedNo === '02' ? '#c06c8470' : (bedassign.bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                                }}>

                                  <Badge
                                    style={{ fontSize: '10px' }}
                                    color={bedassign.patientName ? "danger" : "success"}
                                    tag="h6"
                                  >
                                    {bedassign.patientName && bedassign.patientName !== '' ? "Not Available" : "Available"}
                                  </Badge>
                                </CardFooter>
                              </Card>
                            </div>
                          </Col>
                        </>
                      ))}
                    </Row>
                  ))}
                </div>
              </div>
            </div>
            </TabPanel>
            <TabPanel 
              value="2" 
              style={{ 
                flex: 1, 
                maxHeight: 'calc(100vh - 180px)', 
                overflowY: 'auto', 
                padding: '0',
                transition: 'max-height 0.3s ease'
              }}
            >
            <div className="container m15 p3" >
              <div className="grid-templetcontainer" style={{ justifyItems: "center" }} >
                {Object.entries(occupaidbedsByNumber).map(([bedNo, assignments]) => (
                  <Row key={bedNo} >
                    {assignments.map((patientassign, index) => (
                      <Col key={index} style={{ flex: 0, padding: 0 }}>
                        <div className="bed-assignment-box">
                          <Card
                            className="mb-3"
                            color="danger"
                            outline
                            style={{
                              width: "92px",
                              height: "70px",
                              margin: "5px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <CardBody
                              key={index}
                              style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                            >
                              <CardTitle tag="h6">
                                <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{patientassign.roomNo}</span>
                              </CardTitle>
                              <CardSubtitle tag="h6" className="text-muted ">
                                <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{patientassign.bedNo}</span>
                              </CardSubtitle>
                            </CardBody>

                            <CardFooter style={{
                              padding: '7px', position: 'relative', display: 'flex', top: '-13px', height: '33px', fontSize: '10px', fontWeight: 'bold', lineHeight: 'normal', justifyContent: "space-evenly",
                              background: patientassign.bedNo === '01' ? '#f8b19570' : (patientassign.bedNo === '02' ? '#c06c8470' : (patientassign.bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                            }}>
                              <img src={LogoImg} style={{ width: '20px', height: '20px' }}></img><span className="truncate-hover" style={{ paddingLeft: '5px', lineHeight: 2 }}>{patientassign.patientName}</span>
                            </CardFooter>
                          </Card>
                        </div>
                      </Col>
                    ))}

                  </Row>
                ))}
              </div>
            </div>
            </TabPanel>
            <TabPanel 
              value="3" 
              style={{ 
                flex: 1, 
                maxHeight: 'calc(100vh - 180px)', 
                overflowY: 'auto', 
                padding: '0',
                transition: 'max-height 0.3s ease'
              }}
            >
            <div>
              <div className="container m15 p3" >
                <div className="grid-templetcontainer" style={{ justifyItems: "center" }} >
                  {Object.entries(abailablebedsByNumber).map(([bedNo, assignments]) => (
                    <Row key={bedNo}
                      style={{ display: "flex" }}
                    >
                      {assignments.map((bedassign, index) => (
                        <Col key={index} style={{ flex: 0, padding: 0, width: "33.3%" }}>
                          <div className="bed-assignment-box">
                            <Card
                              className="mb-3"
                              color="primary"
                              outline
                              style={{
                                width: "92px",
                                height: "70px",
                                margin: "5px",
                                justifyContent: "flex-start",
                              }}
                            >
                              <CardBody
                                key={index}
                                onClick={() => handleClick(bedassign)}
                                style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                              >
                                <CardTitle tag="h6">
                                  <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                  <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                                </CardTitle>
                                <CardSubtitle tag="h6" className="text-muted ">
                                  <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                  <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                                </CardSubtitle>
                              </CardBody>

                              <CardFooter style={{
                                padding: '2px 0.6rem 2px 13px', position: 'relative', top: '-13px', height: '33px',
                                background: bedassign.bedNo === '01' ? '#f8b19570' : (bedassign.bedNo === '02' ? '#c06c8470' : (bedassign.bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                              }}>
                                <Badge
                                  style={{ fontSize: '10px' }}
                                  color={bedassign.pid ? "danger" : "success"}
                                  tag="h4"
                                >
                                  {bedassign.pid ? "Not Available" : "Available"}
                                </Badge>

                              </CardFooter>
                            </Card>
                          </div>
                        </Col>
                      ))}

                    </Row>
                  ))}
                </div></div>
            </div>
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
      <Modal isOpen={editModal} style={{ width: '100%' }} sx={{ innerWidth: '100%', outerWidth: '100%' }} toggle={() => setEditModal(false)}>
        <ModalHeader toggle={() => setEditModal(false)}>Assign Bed For Patient</ModalHeader>
        <ModalBody>
          <div>
            <div>
              <p style={{ fontWeight: "bold" }}>Selected bed: {bed}</p>
            </div>
              <Autocomplete
                className="m-3"
                id="patientName"
                options={patientDropdown?.filter(
                  (patient: any) =>
                    patient.name
                )}
                getOptionLabel={(option: any) =>
                  option.name
                }
                value={patientDropdown?.find(
                  (patient: any) => patient.id === bedAssignedData.pid
                )}
                onChange={(event: any, value: any) => {
                  if (value !== null) {
                    handlePatientChange(value.id);
                  }
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Patient"
                    placeholder="Select Patient"
                    margin="none"
                    fullWidth
                  />
                )}
              />
            {/* </div> */}
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={handleSave}>
            Save
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setEditModal(false)}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showModal} toggle={() => setShowModal(false)} centered>
        <ModalHeader toggle={() => setShowModal(false)}>
          <h3>Bed Assign Details</h3>
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="form-control">
              <label
                htmlFor="roomNo"
                className="floating-label"
                style={{ fontWeight: "bold", width: '30%' }}
              >
                Start Room No:
              </label>
              <input style={{ width: '20%' }}
                type="text"
                id="roomNo"
                name="roomNo"
                placeholder="Enter No"
                value={formData.roomNoStart}
                onChange={(e) => setFormData({ ...formData, roomNoStart: e.target.value })}
              ></input>
              <label style={{ width: '2%' }} />
              <label
                htmlFor="bedNo"
                className="floating-label"
                style={{ fontWeight: "bold", width: '28%' }}
              >
                End Room No:
              </label>
              <input style={{ width: '20%' }}
                type="text"
                id="bedNo"
                name="bedNo"
                placeholder="Enter No"
                value={formData.roomNoEnd}
                onChange={(e) => setFormData({ ...formData, roomNoEnd: e.target.value })}
              ></input>
              <div style={{ marginBottom: '15px' }}></div>
              <label
                htmlFor="roomNo"
                className="floating-label"
                style={{ fontWeight: "bold", width: '30%' }}
              >
                Bed No:
              </label>
              <input style={{ width: '20%' }}
                type="text"
                id="roomNo"
                name="roomNo"
                placeholder="Enter No"
                value={formData.bedNo}
                onChange={(e) => setFormData({ ...formData, bedNo: e.target.value })}
              ></input>
              <label style={{ width: '2%' }} />
              <label
                htmlFor="bedNo"
                className="floating-label"
                style={{ fontWeight: "bold", width: '28%' }}
              >
                Type:
              </label>
              <select id="bedNo" style={{ width: '20%', height: '45px', border: '1px solid black', borderRadius: '5px' }} name="bedNo" value={formData.oddOrEven} onChange={(e) => setFormData({ ...formData, oddOrEven: e.target.value })} >
                <option value="" >Select</option>
                <option value="odd" >Odd</option>
                <option value="even" >Even</option>
              </select>
              <Pagination>
                <Pagination.Prev
                  onClick={() => setBedCurrentPage(bedCurrentPage - 1)}
                  disabled={bedCurrentPage === 1}
                />
                {renderPageNumbers()}
                <Pagination.Next
                  onClick={() => setBedCurrentPage(bedCurrentPage + 1)}
                  disabled={bedCurrentPage === Math.ceil(bedAssignData.length / itemsPerPage)}
                />
              </Pagination>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th scope="col" className="text-center">S.No</th>
                    <th scope="col" className="text-center">Room No</th>
                    <th scope="col" className="text-center">Bed No</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(currentBedAssignData) && currentBedAssignData.length > 0 && (
                    currentBedAssignData.map((bedassign: any, index: number) => (
                      <tr key={parseInt(bedassign.roomNo)}>
                        <td>{increment++}</td>
                        <td>{bedassign.roomNo}</td>
                        <td>{bedassign.bedNo}</td>
                      </tr>
                    )))}
                </tbody>
              </Table>
              <ModalFooter style={{ position: 'relative', top: '12px' }}>
                <Button color="info" onClick={handleSaveBed}>
                  Save Changes
                </Button>{" "}
                <Button color="danger" onClick={() => setShowModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
    
  );
};

export default BedAssign;